<template>
  <div class="full-content-box">
    <div class="second-title">
      <div class="shu" />
      考勤数据
    </div>
    <div class="c-btnbox">
      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">姓名：</div>
          <Input
            v-model="searchMainListData.name"
            placeholder="请输入姓名"
            style="width: 300px; margin-right: 14px"
          />
        </div>
        <div class="mb-item">
          <div class="label">班组：</div>
          <Select
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.teamId"
          >
            <Option v-for="item in teamList" :value="item.id" :key="item.id">{{
              item.teamName
            }}</Option>
          </Select>
        </div>

        <div class="mb-item">
          <div class="label">时间范围：</div>
          <DatePicker
            type="daterange"
            @on-change="handelRangeDateChange"
            v-model="rangeDate"
            placement="bottom-end"
            placeholder="选择时间范围"
            style="width: 300px"
          ></DatePicker>
        </div>
        <Button
          type="primary"
          style="margin: 0 14px"
          @click="handleMainListSearch"
          >搜索</Button
        >
        <Button @click="handleMainListSearch('reset')">重置</Button>
      </div>
      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">导出日期范围（月）：</div>
          <DatePicker
            type="daterange"
            @on-change="handelRangeDateChange"
            v-model="exportData.date"
            placement="bottom-end"
            placeholder="请选择考勤表日期"
            style="width: 300px; margin-right: 14px"
          ></DatePicker>
        </div>
        <Button type="primary" style="margin-right: 14px" @click="exportTable"
          >导出考勤数据表</Button
        >
      </div>
    </div>
    <Table :loading="mainLoading" :columns="mainCloums" :data="mainList">
      <template
        v-for="(item, index) in cloumnscopy"
        :slot="item.slot"
        slot-scope="scope"
      >
        <span class="finalClockTime" :key="index">
          <span
            :style="
              scope.row.attendanceRecords[index].educationCodeType === 'GREEN'
                ? 'background:#ccffcc !important'
                : scope.row.attendanceRecords[index].educationCodeType === 'YELLOW'
                ? 'background:#ffff99 !important'
                : scope.row.attendanceRecords[index].educationCodeType === 'RED'
                ? 'background:#ff0000 !important'
                : ''
            "
            >{{ scope.row.attendanceRecords[index].initialClockTime }}</span
          >
          <span
            :style="
              scope.row.attendanceRecords[index].educationCodeType === 'GREEN'
                ? 'background:#ccffcc !important'
                : scope.row.attendanceRecords[index].educationCodeType === 'YELLOW'
                ? 'background:#ffff99 !important'
                : scope.row.attendanceRecords[index].educationCodeType === 'RED'
                ? 'background:#ff0000 !important'
                : ''
            "
            >{{ scope.row.attendanceRecords[index].finalClockTime }}</span
          >
        </span>
      </template>
    </Table>

    <page
      show-total
      class="page-box"
      :page-size="mainSize"
      :total="mainTotal"
      :current="mainPage"
      @on-change="handleMainPageChange"
    />
  </div>
</template>

<script>
export default {
  name: "checkByList",
  data() {
    return {
      searchMainListData: {
        name: "",
        workType: "",
        teamId: "",
        teamIds: [],
        startDate: "",
        endDate: "",
      },
      mainList: [],
      cloumnsTime: [],
      mainCloumsBlack: [
        {
          title: "姓名",
          key: "username",
          fixed: "left",
          width: "80",
        },
        {
          title: "班组名称",
          key: "teamName",
          fixed: "left",
          width: "180",
        },
        {
          title: "出勤天数",
          key: "attendanceDays",
          fixed: "left",
          width: "100",
        },
      ],
      mainCloums: [
        {
          title: "姓名",
          key: "username",
          fixed: "left",
          width: "80",
        },
        {
          title: "班组名称",
          key: "teamName",
          fixed: "left",
          width: "180",
        },
        {
          title: "出勤天数",
          key: "attendanceDays",
          fixed: "left",
          width: "100",
        },
      ],
      cloumnscopy: [],
      mainLoading: false,
      mainPage: 1,
      mainSize: 15,
      mainTotal: 0,
      workTypeList: [
        {
          value: 1,
          label: "普工",
        },
        {
          value: 2,
          label: "高级工",
        },
      ],
      teamList: [],
      rangeDate: "",
      exportData: {
        date: "",
      },
    };
  },
  props: ["ProjectInfo", "ScreenWidth"],
  created() {},
  mounted() {
    this.resetDate();
    this.getFilesTeamList();
    this.handleMainListSearch();
  },
  filters: {},
  watch: {},
  methods: {
    resetDate() {
      let now = new Date(); //当前日期
      let nowMonth = now.getMonth(); //当前月
      let nowYear = now.getFullYear(); //当前年
      //本月的开始时间
      let monthStartDate = new Date(nowYear, nowMonth, 1);
      //本月的结束时间
      let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
      this.rangeDate = [monthStartDate, monthEndDate];
    },
    // 获取班组列表
    getFilesTeamList() {
      let data = {
        pageSize: "1000",
        projectId: this.ProjectInfo.id,
      };
      let res = this.$fetch(`/sapi/team/getTeamsByProjectIdOrName`, {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          this.teamList = res.data;
        } else {
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 获取主表
    getMainList() {
      this.mainLoading = true;
      if (this.rangeDate[0] == "") {
        this.resetDate();
      }
      let params = {
        projectId: this.ProjectInfo.id,
        startDate: this.common.formatDate(this.rangeDate[0]),
        endDate: this.common.formatDate(this.rangeDate[1]),
        pageSize: this.mainSize,
        pageNo: this.mainPage,
        teamId: this.searchMainListData.teamId,
        username: this.searchMainListData.name,
      };
      let token = this.common.getAuth().accessToken;
      this.$axios({
        method: "get",
        url: `https://api.dougongapp.com/dgtech/api/mgmt/attendance/teamitem/statistics`,
        headers: { accessToken: token },
        params,
      }).then((res) => {
        if (res.data.resultStatus) {
          this.cloumnsTime = [];
          this.cloumnscopy = [];
          this.mainTotal = res.data.resultData.totalNum;
          this.mainList = res.data.resultData.dataList;
          console.log("第一个", this.mainList);
          // if (this.mainList[0]) {
          //   this.mainList[0].attendanceRecords.forEach((item) => {
          //     this.cloumnsTime.push(item.attendanceRecords);
          //   });
          // }
          if (res.data.resultData.dataList) {
            console.log(res.data.resultData.dataList, "这是表结构");
            this.handleMainList(res.data.resultData.dataList);
          } else {
            this.mainLoading = false;
            this.mainList = [];
            this.mainCloums = JSON.parse(JSON.stringify(this.mainCloumsBlack));
          }
        } else {
          this.mainLoading = false;
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 处理主表数据
    handleMainList(data) {
      if (data.length > 0) {
        this.handleMainListCloumns(data[0].attendanceRecords);
        let vlist = [];
        data.forEach((val) => {
          val.attendanceRecords.forEach((vall) => {
            var vallTitle =
              vall.clockDate.split("-")[1] + "-" + vall.clockDate.split("-")[2];
            val[
              "" +
                vall.clockDate.split("-")[1] +
                "-" +
                vall.clockDate.split("-")[2]
            ] = JSON.parse(
              JSON.stringify({
                teamName: vall.teamName,
                attendanceDays: vall.attendanceDays,
                username: vall.username,
                workTypeValue: vall.workTypeValue,
                cloumnsTime: [],
              })
            );
          });
          vlist.push(val);
        });

        this.mainList = vlist;
        console.log("第二个", this.mainList);
        this.mainLoading = false;
      } else {
        this.mainList = [];
        this.mainLoading = false;
      }
    },
    handleMainListCloumns(clist) {
      console.log(clist, "dddd");
      let cloumns = JSON.parse(JSON.stringify(this.mainCloumsBlack));
      this.cloumnsTime = [];
      clist.forEach((val) => {
        let test =
          val.clockDate.split("-")[1] + "-" + val.clockDate.split("-")[2];
        this.cloumnsTime.push({
          clockDate: test,
          educationCodeType: val.educationCodeType,
          finalClockTime: val.finalClockTime,
          initialClockTime: val.initialClockTime,
        });
        this.cloumnscopy.push({
          title: test,
          key: test,
          slot: test,
          width: "180",
          codeType: val.codeType,
          clockDate: test,
          educationCodeType: val.educationCodeType,
          finalClockTime: val.finalClockTime,
          initialClockTime: val.initialClockTime,
        });
        cloumns.push({
          title: test,
          key: test,
          slot: test,
          width: "110",
          codeType: val.codeType,
        });
      });
      this.mainCloums = cloumns;
    },
    // 主表搜索
    handleMainListSearch(type) {
      if (type == "reset") {
        this.searchMainListData = {
          name: "",
          workType: "",
          teamId: "",
          startDate: "",
          endDate: "",
        };
        this.resetDate();
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      this.handleMainListSearch();
    },
    handelRangeDateChange(e) {
      var date1 = new Date(e[0]);
      var date2 = new Date(e[1]);
    },
    downDoc() {},

    handelExportDateChange(e) {
      this.exportData.date = e;
    },
    exportTable() {
      if (
        this.exportData.date == "" ||
        this.exportData.date[0] == "" ||
        this.exportData.date[1] == ""
      ) {
        this.$Message.error("请选择导出考勤数据日期范围");
        return false;
      }
      let params = {
        projectId: this.ProjectInfo.id,
        startDate: this.common.formatDate(this.exportData.date[0]),
        endDate: this.common.formatDate(this.exportData.date[1]),
        // teamId: "",
        // workType: "",
      };
      // this.$fetch(
      //   `/sapi/person/access/record/list/excel`,
      //   {
      //     method: "post",
      //     data,
      //   }
      // ).then((res) => {
      //   console.log(res)
      // });
      let token = this.common.getAuth().accessToken;
      this.$axios({
        method: "get",
        url: `https://api.dougongapp.com/dgtech/api/mgmt/attendance/teamitem/statistics/export`,
        headers: { accessToken: token },
        params,
        responseType: "blob",
      })
        .then((res) => {
          let blob = res.data;
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = (e) => {
            let a = document.createElement("a");
            a.download = `考勤数据.xlsx`;
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          };
        })
        .catch((err) => {
         // this.$Message.error(err);
        });
    },
  },
  destory() {},
};
</script>

<style lang="less" scoped>
@import "../public/style.less";

.full-content-box {
}

.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;

  .mb-item {
    margin-bottom: 14px;
  }
}

.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tcb-item {
  display: flex;
  flex-wrap: wrap;

  .tcbi-item {
    margin-bottom: 14px;
  }
}
.finalClockTime {
  display: flex;
  flex-direction: column;
}
</style>