<template>
  <div class="full-content-box">
    <Button
      type="primary"
      class="addRules"
      style="margin-right: 14px; margin-bottom: 14px; width: 150px"
      @click="addRules"
      >添加考勤规则</Button
    >
    <Table :loading="mainLoading" :columns="mainCloums" :data="mainList">
      <template slot-scope="{ row, index }" slot="attendanceRestriction">
        {{
          row.attendanceRestriction == "GREEN_CODE"
            ? "绿码打卡"
            : row.attendanceRestriction == "GREEN_OR_YELLOW_CODE"
            ? "绿码+黄码打卡"
            : "无"
        }}
      </template>
      <template slot-scope="{ row, index }" slot="clockType">
        {{ row.clockType == "REGULAR" ? "常规打卡" : "人脸识别打卡" }}
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <div style="display: flex">
          <div
            style="margin-right: 4px; width: 120px; color: rgb(45, 183, 245)"
            @click="editRules(row)"
          >
            编辑规则
          </div>
          <div style="width: 120px; color: red" @click="deleteRules(row)">
            删除
          </div>
        </div>
      </template>
    </Table>
    <Modal
      width="60%"
      v-model="copySafeDiscloseFlag"
      :title="title"
      :mask-closable="false"
    >
      <template slot="footer">
        <Button
          v-if="title == '填写考勤规则信息'"
          type="primary"
          @click="copySafeDiscloseFlag = false"
          >取消</Button
        >
        <Button v-else type="primary" @click="step">上一步</Button>
        <Button v-if="title == '填写考勤规则信息'" @click="next" type="primary"
          >下一步</Button
        >
        <Button v-else type="primary" @click="complete">完成</Button>
      </template>
      <div v-show="title == '填写考勤规则信息'">
        <Form ref="payForm" :model="payForm" :rules="rules1">
          <FormItem label="考勤规则名称：" prop="ruleName">
            <Row>
              <Col span="20">
                <Input
                  placeholder="请输入考勤规则名称"
                  v-model.trim="payForm.ruleName"
                  :maxLength="20"
                />
              </Col>
            </Row>
          </FormItem>
          <FormItem label="考勤方式：" prop="attendanceType">
            <Row>
              <Col span="20">
                <RadioGroup v-model="payForm.attendanceType">
                  <Radio label="GPS">GPS定位</Radio>
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="考勤限制：" prop="attendanceRestriction">
            <Row>
              <Col span="20">
                <RadioGroup v-model="payForm.attendanceRestriction">
                  <Radio label="GREEN_CODE">绿码打卡</Radio>
                  <Radio label="GREEN_OR_YELLOW_CODE">绿码+黄码打卡</Radio>
                  <Radio label="NONE">无</Radio>
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="打卡方式：" prop="clockType">
            <Row>
              <Col span="20">
                <RadioGroup v-model="payForm.clockType">
                  <Radio label="FACE_RECOGNITION">人脸识别打卡</Radio>
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <!-- <FormItem label="详细地址：" prop="attendanceLocationDetailAddress">
            <Row>
              <Col span="20">
                <Input
                  placeholder="请输入考勤详细地址"
                  v-model.trim="payForm.attendanceLocationDetailAddress"
                  :maxLength="20"
                />
              </Col>
            </Row>
          </FormItem> -->

          <FormItem label="考勤状态监控服务：" prop="attendanceStatusService">
            <Row>
              <Col span="20">
                <RadioGroup v-model="payForm.attendanceStatusService">
                  <Radio label="true">是</Radio>
                  <Radio label="false">否</Radio>
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          
          <FormItem  label="项目地址：" prop="attendanceLocationDetailAddress">
            <Row>
              <Col span="20">
                <Input
                  disabled
                  placeholder="请输入项目地址"
                  v-model.trim="payForm.attendanceLocationDetailAddress"
                  :maxLength="20"
                />
                 
                <!-- <Input v-model="region.join()" :maxLength="20" disabled /> -->
                <!-- <span v-model="regione"></span> -->
                <div style="min-width: 100px">
                  经纬度
                  <span
                    v-if="
                      payForm.attendanceLocationLongitude &&
                      payForm.attendanceLocationLatitude
                    "
                  >
                    {{ payForm.attendanceLocationLongitude }},{{
                      payForm.attendanceLocationLatitude
                    }}
                  </span>
                 
                </div>
                <ModalMap
                  v-show="isOpen"
                  :lnglat="laXY"
                  @onSure="sureAddress"
                  @onSelect="onSelectAddress"
                  style="margin-top: 20px"
                />
              </Col>
              <Col span="3" style="margin-left:30px">
                  <Button @click="openOrClose" type="primary" >
                    <span v-if="!isOpen">展开地图</span>
                    <span v-else>收起地图</span> 
                  </Button>
              </Col>
            </Row>
          </FormItem>
          <!-- 考勤电子围栏 -->
          <!-- prop="dianziweilan" -->
          <FormItem label="考勤范围：" style="margin-top:10px;margin-left:12px" >
            <Row>
              <Col span="20">
                  <Button  type="primary" @click="isOpen1=!isOpen1"
                    >
                    <span v-if="!isOpen1">展开地图</span>
                    <span v-else>收起地图</span> 
                    </Button
                  > 
                  <Button v-if="isOpen1" style="margin-left:20px"  type="primary" @click="clear"
                    >清除</Button
                  > 
                  <div v-show="isOpen1"  style="position:relative"> 
                     <div class="inputBox">
                        <input type="text" placeholder="请输入关键字" id="search1" />
                      </div>
                     <div  id="map" style="margin-top:10px"></div>
                  </div>

               
                </Col>
                <!-- 
                  <Col span="3" style="margin-left:30px">
                  <Button  type="primary" @click="isOpen1=!isOpen1"
                    >
                    <span v-if="!isOpen1">展开地图</span>
                    <span v-else>收起地图</span> 
                    </Button
                  > 
                  <Button style="margin-top:20px"  type="primary" @click="clear"
                    >清除</Button
                  >  
                </Col> 
                -->
            </Row>
          
          </FormItem>
          <!-- <FormItem label="有效范围：">
            <Row>
              <Col span="20">
                <InputNumber
                  v-model="payForm.effectiveDistance"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="请输入你的反馈"
                />
                米
              </Col>
            </Row>
          </FormItem> -->
        </Form>
      </div>
      <div v-show="title !== '填写考勤规则信息'">
        <div class="titlebox">
          <div
            @click="changeTab(0)"
            :class="active == 0 ? 'actworkers' : ''"
            class="workers"
          >
            工人补卡申请审批流程
          </div>
          <div
            @click="changeTab(1)"
            :class="active == 1 ? 'actworkers' : ''"
            class="workers"
          >
            班组长补卡申请审批流程
          </div>
        </div>
        <div style="text-align: center; font-weight: Bold; margin: 10px 0">
          {{ active == 0 ? "工人审批流程设置" : "班组长审批流程设置" }}
        </div>
        <Form ref="payForm2" :model="payForm" :rules="rules2">
          <FormItem
            label="选择审核流程："
            prop="workerApprovalProcessType"
            v-if="active == 0"
          >
            <Row>
              <Col span="24">
                <RadioGroup v-model="payForm.workerApprovalProcessType">
                  <Radio label="TEAM_LEADER">工人->班组长</Radio>
                  <Radio label="PROJECT_LEADER">工人->项目管理员</Radio>
                  <Radio label="TEAM_AND_PROJECT_LEADER"
                    >工人->班组长->项目管理员</Radio
                  >
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <FormItem
            v-if="
              payForm.workerApprovalProcessType != 'TEAM_LEADER' && active == 0
            "
            label="选择审批人："
            porp="workerApproverIdentityId"
          >
            <Row>
              <Col span="20">
                <Select
                  v-model="payForm.workerApproverIdentityId"
                  @on-change="syncDict(payForm.workerApproverIdentityId)"
                >
                  <Option
                    v-for="item in approverList"
                    :value="item.approverIdentityId"
                    :key="item.approverIdentityId"
                    >{{ item.approverRoleName }}:
                    {{ item.approverUsername }}</Option
                  >
                </Select>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="选择审核流程：" v-if="active == 1">
            <Row>
              <Col span="20">
                <RadioGroup v-model="workerApprovalProcessType">
                  <Radio label="TEAM_AND_PROJECT_LEADER"
                    >班组长->项目管理员</Radio
                  >
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <FormItem
            label="选择审批人："
            v-if="active == 1"
            prop="teamLeaderApproverIdentityId"
          >
            <Row>
              <Col span="20">
                <Select
                  v-model="payForm.teamLeaderApproverIdentityId"
                  @on-change="syncDict(payForm.teamLeaderApproverIdentityId)"
                >
                  <Option
                    v-for="item in approverList"
                    :value="item.approverIdentityId"
                    :key="item.approverIdentityId"
                    >{{ item.approverRoleName }}:
                    {{ item.approverUsername }}</Option
                  >
                </Select>
              </Col>
            </Row>
          </FormItem>
        </Form>
        <!-- <Form v-else ref="payForm3" :model="payForm" :rules="rules3">
      
          <FormItem label="选择审核流程：">
            <Row>
              <Col span="20">
                <RadioGroup v-model="workerApprovalProcessType">
                  <Radio label="TEAM_AND_PROJECT_LEADER"
                    >班组长->项目管理员</Radio
                  >
                </RadioGroup>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="选择审批人：" prop="teamLeaderApproverIdentityId">
            <Row>
              <Col span="20">
                <Select
                  v-model="payForm.teamLeaderApproverIdentityId"
                  @on-change="syncDict(payForm.teamLeaderApproverIdentityId)"
                >
                  <Option
                    v-for="item in approverList"
                    :value="item.approverIdentityId"
                    :key="item.approverIdentityId"
                    >{{ item.approverRoleName }}:
                    {{ item.approverUsername }}</Option
                  >
                </Select>
              </Col>
            </Row>
          </FormItem>
        </Form>  -->
      </div>
    </Modal>
  </div>
</template>

<script>
import { options } from "../../../utils/region";
import ModalMap from "../../../components/ModalMap.vue";
import AMap from "AMap";
import M from "minimatch";
var placeSearch;
export default {
  name: "AttendanceRules",
  data() {
    return {
      copySafeDiscloseFlag: false,
      options,
      rules1: {
        ruleName: [{ required: true, message: "请输入", trigger: "blur" }],
        attendanceType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        attendanceRestriction: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        clockType: [{ required: true, message: "请选择", trigger: "change" }],
        attendanceStatusService: [{ required: true, message: "请选择", trigger: "change" }],
        attendanceLocationDetailAddress: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        dianziweilan: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
      },
      rules2: {
        workerApprovalProcessType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        workerApproverIdentityId: [
          { required: true, message: "请选择", trigger: ["blur"] },
        ],
        workerApprovalProcessType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        teamLeaderApproverIdentityId: [
          { required: true, message: "请选择", trigger: ["blur"] },
        ],
        workerApprovalProcessType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        teamLeaderApproverIdentityId: [
          { required: true, message: "请选择", trigger: ["blur"] },
        ],
      },
      rules3: {
        workerApprovalProcessType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        teamLeaderApproverIdentityId: [
          { required: true, message: "请选择", trigger: ["blur"] },
        ],
      },
      detailAddress: "",
      ruleId: 0,
      payForm: {
        ruleName: "",
        attendanceType: "",
        attendanceRestriction: "",
        clockType: "",
        attendanceStatusService:"",
        // effectiveDistance: 100,
        attendanceLocationProvince: "",
        attendanceLocationCity: "",
        attendanceLocationDistrict: "",
        attendanceLocationLongitude: "",
        attendanceLocationLatitude: "",
        attendanceLocationDetailAddress: "",
        workerApprovalProcessType: "",
        workerApproverIdentityId: "",
        teamLeaderApproverIdentityId: "",

        attendanceAreas: [],//多个   电子围栏数据

      },
      attendanceArea:[], //单个电子围栏的数组


      workerApprovalProcessType: "TEAM_AND_PROJECT_LEADER",
      addressCloums: [
        {
          title: "考勤地点",
          key: "name",
          fixed: "left",
        },
        {
          title: "详细地址",
          key: "name",
          fixed: "left",
        },
        {
          title: "经纬度",
          key: "name",
          fixed: "left",
        },
        {
          title: "操作",
          key: "name",
          // slot: "action",
          fixed: "left",
        },
      ],
      active: 0,
      laXY: null,
      title: "填写考勤规则信息",
      approverList: [],
      region: [],
      lng: null,
      lat: null,
      searchMainListData: {
        name: "",
        workType: "",
        teamIds: [],
        startDate: "",
        endDate: "",
      },
      mainList: [],
      cloumnsTime: [],
      mainCloums: [
        {
          title: "规则名称",
          key: "ruleName",
          fixed: "left",
        },
        {
          title: "打卡规则",
          slot: "attendanceRestriction",
          key: "attendanceRestriction",
          fixed: "left",
        },
        {
          title: "打卡类型",
          key: "attendanceType",
          fixed: "left",
        },
        {
          title: "打卡方式",
          slot: "clockType",
          key: "clockType",
          fixed: "left",
        },
        {
          title: "操作人",
          key: "editorName",
          fixed: "left",
        },
        {
          title: "操作时间",
          key: "updateTime",
          fixed: "left",
        },
        {
          title: "操作",
          slot: "action",
          fixed: "left",
        },
      ],
      mainLoading: false,
      mainPage: 1,
      mainSize: 15,
      mainTotal: 0,
      workTypeList: [
        {
          value: 1,
          label: "普工",
        },
        {
          value: 2,
          label: "高级工",
        },
      ],
      teamList: [
        {
          value: 1,
          label: "班组A",
        },
        {
          value: 2,
          label: "班组B",
        },
      ],
      rangeDate: "",
      exportData: {
        date: "",
      },

      // polygons: [],
      // polygonList: [],
      map: null,
      flag: true,
      marker: [],
      MouseTool: null,
      polygonsH: [],
      isCoincidence: false,
      isOpen:false,
      isOpen1:true,//默认展开


      AMap: {},


    };
  },
  props: ["ProjectInfo", "ScreenWidth"],
  created() {},
  mounted() {
    this.getMainList();
    this.getApproverList();
    this.AMapInit();
    this.getLongitudeLatitude();
  },
  components: {
    ModalMap,
  },
  filters: {},
  watch: {},
  methods: {
    openOrClose(){
      this.isOpen=!this.isOpen
    },
    clear() {
      this.map.remove(this.polygonsH);
      this.MouseTool.close(true);
      this.payForm.attendanceAreas = [];
      this.markerInit();
    },
    // 获取范围
    getRange() {
      this.polygonsH = [];
      let locationRange = this.payForm.attendanceAreas;

      locationRange.forEach((item,index)=>{
          [item.attendanceArea].map((res, index) => {
            let polygon = new AMap.Polygon({
              map: this.map,
              path: res.map(
                (obj) => new AMap.LngLat(obj.longitude, obj.latitude)
              ),
              strokeWeight: 6, // 线条宽度，默认为 1
              strokeColor: "#ff2510", // 线条颜色
              fillColor: "#ff2510",
              fillOpacity: 0.4,
            });
            this.polygonsH.push(polygon);
        });
         this.map.setFitView();       

      })

    },

    //设置电子围栏的中心位置
    setAttendanceRangeCenter() {
      this.marker = this.laXY;
      this.map.setCenter(this.marker);
    },
    mapInit() {


      const AMap =  window.AMap;

      this.map = new AMap.Map("map");


      let that=this;

      AMap.plugin(
        ["AMap.ToolBar", "AMap.Autocomplete", "AMap.PlaceSearch", "AMap.Geocoder","AMap.Marker"],
        function () {
          that.map.addControl(
            new AMap.ToolBar({
              locate: false,
            })
          );
          const autoOptions = {
            city: "",
            input: "search1",
          };
          let autocomplete = new AMap.Autocomplete(autoOptions);
          // map.addControl(autocomplete);
          //搜索事件
          const placeSearch = new AMap.PlaceSearch({
            city: "",
            map: that.map,
          });
          // console.log(AMap.event);
          AMap.event.addListener(autocomplete, "select", function (e) {
            // that.$emit("onSelect", e.poi);
   
            // that.onSelectAddress(e.poi)

            console.log(e.poi);
            that.marker = [e.poi.location.lng,e.poi.location.lat];
            that.map.setCenter(that.marker);

            that.marker.setMap(null);
            that.marker = null;
            that.map.setZoom(20);


            // placeSearch.search(e.poi.name);
          });

        }
      );







      
      if (this.ruleId == 0) {
        this.setAttendanceRangeCenter();
        this.map.setZoom(20);
      }
      this.markerInit();
    },
    //范围
    markerInit() {
        let that = this;
        that.MouseTool = new AMap.MouseTool(this.map);
        that.MouseTool.polygon({
          strokeColor: "#ff2510",
          strokeOpacity: 1,
          strokeWeight: 6,
          strokeOpacity: 1,
          fillColor: "#ff2510",
          fillOpacity: 0.4,
          strokeStyle: "solid",
        });
        that.MouseTool.on("draw", function (event) {
            // event.obj 为绘制出来的覆盖物对象
            let a={
              attendanceArea:[],
            }
            that.flag = true;
            for (let item in event.obj.w.path) {
              a.attendanceArea.push({
                latitude: event.obj.w.path[item].lat,
                longitude: event.obj.w.path[item].lng,
              });
            }
            that.payForm.attendanceAreas.push(a);

        });
    },
    getLongitudeLatitude() {
      console.log("=======================");
      AMap.plugin("AMap.Geolocation", () => {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });

        geolocation.getCityInfo((status, result) => {
          //只能获取当前用户所在城市和城市的经纬度
          if (status == "complete") {
            console.log("result", result);
            this.laXY = result.center;
          }
        });
        geolocation.getCurrentPosition((status, result) => {
          //获取用户当前的精确位置
          if (status == "complete") {
            console.log("位置信息", result);
            this.laXY = [];
            this.laXY[0] = result.position.lng;
            this.laXY[1] = result.position.lat;
          }
        });
      });
    },
    // 审批流程切换时
    changeTab(index) {
      this.active = index;
      if (index == 1) {
        this.workerApprovalProcessType = "TEAM_AND_PROJECT_LEADER";
      }
    },
    //添加考勤规则
    addRules() {
      if(this.mainList.length > 0){
        this.$Message.info("当前已有考勤规则")
        return
      }
      if (this.ruleId != 0) {
        this.payForm = {
          ruleName: "",
          attendanceType: "",
          attendanceRestriction: "",
          clockType: "",
          attendanceStatusService:"",
          // effectiveDistance: 100,
          attendanceLocationProvince: "",
          attendanceLocationCity: "",
          attendanceLocationDistrict: "",
          attendanceLocationLongitude: "",
          attendanceLocationLatitude: "",
          attendanceLocationDetailAddress: "",
          workerApprovalProcessType: "",
          workerApproverIdentityId: "",
          teamLeaderApproverIdentityId: "",
        };
      }
      this.ruleId = 0;
      this.title = "填写考勤规则信息";
      this.copySafeDiscloseFlag = true;
      this.region = [];
      this.AMapInit();


      //新增的时候 的 电子围栏初始化
      this.payForm.attendanceAreas=[],

      this.$nextTick(() => {
        this.mapInit();
      });
    },
    //下一步
    next() {
      this.$refs["payForm"].validate((valid) => {
        if (valid) {
          if (this.payForm.attendanceLocationLongitude == "") {
            this.$Message.info("请在地图上点击后再试");
          }else if (this.payForm.attendanceAreas.length==0) {
            this.$Message.info("请设置考勤范围");
          }
          else {
            this.title = "设置审核流程";
          }
        }
      });
    },
    //上一步
    step() {
      console.log(123);
      this.title = "填写考勤规则信息";


    },
    //删除规则
    deleteRules(row) {
      this.$Modal.confirm({
        title: "提示",
        content: "<p>是否删除该考勤规则</p>",
        onOk: () => {
          let token = this.common.getAuth().accessToken;
          this.$axios({
            method: "delete",
            url: `https://api.dougongapp.com/dgtech/api/mgmt/attendance/rules/${row.ruleId}`,
            headers: { accessToken: token },
          }).then((res) => {
            if (res.data.resultStatus) {
              this.$Message.success("删除成功");
              this.getMainList();
            } else {
              this.$Message.info(res.data.errorMessage);
            }
          });
        },
        onCancel: () => {},
      });
    },
    //考勤详情
    editRules(row) {
      this.title = "填写考勤规则信息";
      let token = this.common.getAuth().accessToken;
      this.ruleId = row.ruleId;
      this.$axios({
        method: "get",
        url: `https://api.dougongapp.com/dgtech/api/mgmt/attendance/rules/${row.ruleId}`,
        headers: { accessToken: token },
      }).then((res) => {
        if (res.data.resultStatus) {
          this.payForm = res.data.resultData;
          this.payForm.attendanceStatusService = res.data.resultData.attendanceStatusService==true?'true':'false';

          console.log(this.payForm, "payForm");
          this.laXY = [];
          this.laXY[0] = this.payForm.attendanceLocationLongitude;
          this.laXY[1] = this.payForm.attendanceLocationLatitude;
          this.copySafeDiscloseFlag = true;
          // this.payForm.attendanceLocationDetailAddress =
          //   this.payForm.attendanceLocationProvince +
          //   this.payForm.attendanceLocationCity +
          //   this.payForm.attendanceLocationDistrict +
          //   this.payForm.attendanceLocationDetailAddress;
            // 电子围栏数据
            if (!this.payForm.attendanceAreas) {
              this.payForm.attendanceAreas = [];
            } 

            this.mapInit();
            // //根据选中的项目地址中心  设置电子围栏的中心
            this.setAttendanceRangeCenter();
            this.map.setZoom(20);

            this.getRange();


        } else {
          this.$Message.info(res.data.errorMessage);
        }
      });

     

    },
    //完成
    complete() {
      var fromstr = "payForm2";
      // if (this.active == 1) {
      //   fromstr = "payForm3";
      // }
      this.payForm.attendanceStatusService=this.payForm.attendanceStatusService=='true'?true:false
      let obj = { ...this.payForm };
      this.$refs[fromstr].validate((valid) => {
        if (valid) {
          let token = this.common.getAuth().accessToken;
          if (
            this.payForm.workerApprovalProcessType != "TEAM_LEADER" &&
            this.payForm.workerApproverIdentityId == ""
          ) {
            this.$Message.info("请选择工人补卡审批人");
            return;
          } else if (this.payForm.teamLeaderApproverIdentityId == "") {
            this.$Message.info("请选择班组长补卡审批人");
            return;
            考勤;
          }
          if (this.payForm.workerApprovalProcessType == "TEAM_LEADER") {
            delete obj.workerApproverIdentityId;
          }
          (obj.projectId = this.ProjectInfo.id),
            this.$axios({
              method: "post",
              url: `https://api.dougongapp.com/dgtech/api/mgmt/attendance/rules/${this.ruleId}`,
              headers: { accessToken: token },
              data: obj,
            }).then((res) => {
              console.log("++++++++++", res);
              if (res.data.resultStatus) {
                this.copySafeDiscloseFlag = false;
                this.getMainList();
                this.$Message.success("提交成功");
              } else {
                this.$Message.info(res.data.errorMessage);
              }
            });
        }
      });
    },
    //选择审批人
    syncDict(event) {
      console.log(event);
      if (this.active == 0) {
        this.payForm.workerApproverIdentityId = event;
      } else {
        this.payForm.teamLeaderApproverIdentityId = event;
      }
    },
    //地图
    AMapInit() {
      var map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: 10,
      });
      AMap.service(["AMap.PlaceSearch"], function () {
        //构造地点查询类
        placeSearch = new AMap.PlaceSearch({
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          citylimit: false, //是否强制限制在设置的城市内搜索
          map: map, // 展现结果的地图实例
          panel: "panel", // 结果列表将在此容器中进行展示。
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          renderStyle: "default",
        });
      });
      console.log("第三步", this.selectAddress);
      // 添加列表点选监听事件
      AMap.event.addListener(placeSearch, "selectChanged", this.selectAddress);
    },
    selectAddress(e) {
      console.log("第二步");
      //这里获得点选地点的经纬度
      let location = e.selected.data.location;
      this.payForm.attendanceLocationLongitude = location.lng;
      this.payForm.attendanceLocationLatitude = location.lat;

      //根据选中的项目地址中心  设置电子围栏的中心
      this.setAttendanceRangeCenter()

      this.laXY[0] = location.lng;
      this.laXY[1] = location.lat;
    },
    //搜索结果选中
    onSelectAddress(data) {
      var arr = data.district.split("省");
      console.log("++++++++++++", data, arr);
      this.payForm.attendanceLocationLongitude = ''
      this.payForm.attendanceLocationLatitude = ''
      if (arr.length == 2) {
        this.payForm.attendanceLocationProvince = arr[0] + "省";
        var city = arr[1] ? arr[1].split("市") : [];
        console.log("city", city);
        if (city[1] != "") {
          this.payForm.attendanceLocationCity = city[0] + "市";
          this.payForm.attendanceLocationDistrict = city[1];
        } else {
          this.payForm.attendanceLocationCity = city[0] + "市";
          this.payForm.attendanceLocationDistrict = data.address;
        }
      } else {
        var city = arr[0] ? arr[0].split("市") : [];
        console.log("city", arr[0], city);
        if (city[1] != "") {
          this.payForm.attendanceLocationProvince = city[0] + "市";
          this.payForm.attendanceLocationCity = city[0] + "市";
          this.payForm.attendanceLocationDistrict = city[1];
        } else {
          this.payForm.attendanceLocationProvince = city[0] + "市";
          this.payForm.attendanceLocationCity = city[0] + "市";
          this.payForm.attendanceLocationDistrict = data.address;
        }
      }

      this.payForm.attendanceLocationDetailAddress = data.district + data.name;
      console.log(
        "=================",
        this.payForm.attendanceLocationProvince,
        this.payForm.attendanceLocationCity,
        this.payForm.attendanceLocationDistrict
      );
      if (data.location != "") {
        this.laXY = [];
        this.laXY[0] = data.location.lng ? data.location.lng : "";
        this.laXY[1] = data.location.lat ? data.location.lat : "";
        this.payForm.attendanceLocationLongitude = data.location.lng
          ? data.location.lng
          : "";
        this.payForm.attendanceLocationLatitude = data.location.lat
          ? data.location.lat
          : "";
        console.log("这是经纬度", this.laXY);
      }

      //根据选中的项目地址中心  设置电子围栏的中心
      this.setAttendanceRangeCenter()
    },
    
    // 地图选中
    sureAddress(data) {
      console.log("第一步", data);
      this.laXY = data.lnglat;
      this.payForm.attendanceLocationLongitude = this.laXY[0];
      this.payForm.attendanceLocationLatitude = this.laXY[1];

      //根据选中的项目地址中心  设置电子围栏的中心
      this.setAttendanceRangeCenter()

      if (data.province == data.city) {
        this.region = [data.province, data.district];
        this.detailAddress = data.town + data.detailAddress;
        this.payForm.attendanceLocationProvince = data.province;
        this.payForm.attendanceLocationCity = data.city;
        this.payForm.attendanceLocationDistrict = data.district;
        this.payForm.attendanceLocationDetailAddress =
          data.province + data.city + data.district + data.detailAddress;
      } else {
        this.region = [data.province, data.city, data.district];
        this.detailAddress = data.town + data.detailAddress;
        this.payForm.attendanceLocationProvince = data.province;
        this.payForm.attendanceLocationCity = data.city;
        this.payForm.attendanceLocationDistrict = data.district;
        this.payForm.attendanceLocationDetailAddress =
          data.province + data.city + data.district + data.detailAddress;
      }
    },
    //获取审批人列表
    getApproverList() {
      let token = this.common.getAuth().accessToken;
      let params = {
        projectId: this.ProjectInfo.id,
      };
      this.$axios({
        method: "get",
        url: `https://api.dougongapp.com/dgtech/api/mgmt/attendance/approvers`,
        headers: { accessToken: token },
        params,
      }).then((res) => {
        console.log("++++++++++", res);
        if (res.data.resultStatus) {
          this.approverList = res.data.resultData;
        } else {
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    handleMainPageChange() {},
    //获取考勤规则列表
    getMainList() {
      this.mainLoading = true;
      let params = {
        pageSize: 10,
        pageNo: 1,
        projectId: this.ProjectInfo.id,
      };
      let token = this.common.getAuth().accessToken;
      this.$axios({
        method: "get",
        url: `https://api.dougongapp.com/dgtech/api/mgmt/attendance/rules`,
        headers: { accessToken: token },
        params,
      }).then((res) => {
        this.mainLoading = false;
        if (res.data.resultStatus) {
          this.mainList = res.data.resultData.dgAttendanceRule;
        } else {
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../public/style.less";

.full-content-box {
}

.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;

  .mb-item {
    margin-bottom: 14px;
  }
}

.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tcb-item {
  display: flex;
  flex-wrap: wrap;

  .tcbi-item {
    margin-bottom: 14px;
  }
}

.addRules {
  position: relative;
  left: 90%;
  margin-bottom: 40px;
}

.titlebox {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  .workers {
    width: 50%;
    padding: 10px 0;
    text-align: center;
    position: relative;
  }

  .actworkers {
    width: 50%;
    text-align: center;
    position: relative;
    color: rgba(33, 155, 255, 1);
  }

  .actworkers::after {
    content: "";
    width: 60px;
    height: 3px;
    background: rgba(33, 155, 255, 1);
    position: absolute;
    bottom: 0px;

    left: 50%;
    transform: translateX(-50%);
  }
}
.amap-sug-result {
  width: 150px;
  overflow: hidden;
  margin-top: 5px;
}
.mapBox {
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.2);
  // overflow: hidden;
  // z-index: 1000;
  .showBox {
    width: 100%;
    height: 400px;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: #fff;
    // position: absolute;
    // left: 0;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // margin: auto;
    .upBox {
      width: 100%;
      height: 100%;
      padding: 15px;
      box-sizing: border-box;
      position: relative;
    }
    .downBox {
      width: 100%;
      height: 90px;
      padding: 10px 20px;
      box-sizing: border-box;
      display: flex;
      p {
        padding: 0;
        margin: 0;
      }
    }
  }
  .inputBox {
    display: flex;
    position: absolute;
    overflow: hidden;
    right: 20px;
    top: 20px;
    z-index: 10000;
    width: 50%;
    height: 60px;
    border-radius: 5px;
    background-color: #fff;
    // border: 1px solid rgb(216, 216, 216);
    padding: 15px;
    box-sizing: border-box;
    .title {
      width: 6rem;
      text-align: justify;
      padding: 0.4rem 0.7rem;
      display: inline-block;
      text-justify: distribute-all-lines;
      text-align-last: justify;
      -moz-text-align-last: justify;
      -webkit-text-align-last: justify;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      font-size: 12px;
    }
    input {
      flex: 1;
      width: 100%;
      background: #fff;
      padding: 0.375rem 0.75rem;
      border: 1px solid #ced4da;
      height: 40px;
      box-sizing: border-box;
      &:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba(128, 189, 255, 0.1);
      }
    }
  }
  .imgBox {
    position: absolute;
    right: 20px;
    bottom: 17px;
    z-index: 10000;
    width: 150px;
    height: 155px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    img {
      display: block;
      width: 110px;
      height: 110px;
      margin: 10px auto 0;
    }
    > div {
      text-align: center;
      line-height: 30px;
    }
  }
  .addressBox {
    flex-grow: 1;
    .name {
      height: 40px;
      padding: 5px 0;
      box-sizing: border-box;
      span {
        font-size: 14px;
        padding-right: 20px;
        line-height: 30px;
        color: #4d4d4d;
      }
      input {
        width: 175px;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #d3d3d3;
        border-radius: 4px;
        padding: 0 15px;
        color: #b5b5b5;
      }
    }
    .address {
      font-size: 14px;
      color: #4d4d4d;
      span {
        padding: 0 10px;
      }
    }
  }
  .phoneBox {
    min-width: 215px;
    line-height: 70px;
    text-align: center;
    a {
      color: #00ba0e;
      font-size: 16px;
      text-decoration: underline;
    }
  }
  .btnBox {
    min-width: 200px;
    button {
      width: 80px;
      height: 30px;
      margin-top: 20px;
      border: 0;
      border-radius: 4px;
      cursor: pointer;
      &.close {
        float: left;
        background: #fff;
        border: 1px solid #e5e5e5;
        color: #a5a5a5;
      }
      &.submit {
        float: right;
        background: #00ba0e;
        color: #fff;
      }
    }
  }
  #gaoMap {
    width: 100%;
    height: 100%;
  }
  #search {
    height: 30px;
  }
}
#map{
  width: 96%;
  height: 400px;
  margin-left: 18px;
}
.inputBox {
    display: flex;
    position: absolute;
    overflow: hidden;
    right: 20px;
    top: 20px;
    z-index: 10000;
    width: 50%;
    height: 60px;
    border-radius: 5px;
    background-color: #fff;
    // border: 1px solid rgb(216, 216, 216);
    padding: 15px;
    box-sizing: border-box;
    .title {
      width: 6rem;
      text-align: justify;
      padding: 0.4rem 0.7rem;
      display: inline-block;
      text-justify: distribute-all-lines;
      text-align-last: justify;
      -moz-text-align-last: justify;
      -webkit-text-align-last: justify;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      font-size: 12px;
    }
    input {
      flex: 1;
      width: 100%;
      background: #fff;
      padding: 0.375rem 0.75rem;
      border: 1px solid #ced4da;
      height: 40px;
      box-sizing: border-box;
      &:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba(128, 189, 255, 0.1);
      }
    }
  }
</style>