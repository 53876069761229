<template>
  <div class="full-content-box">
    <div class="second-title">
      <div class="shu" />
      花名册管理
    </div>
    <div class="c-btnbox">
      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">关键字：</div>
          <Input
            placeholder="姓名/身份证/手机号码"
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.searchValue"
          />
        </div>
        <div class="mb-item">
          <div class="label">班组：</div>
          <Select
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.teamId"
          >
            <Option v-for="item in teamList" :value="item.id" :key="item.id">{{
              item.teamName
            }}</Option>
          </Select>
        </div>
        <div class="mb-item">
          <div class="label">工种：</div>
          <Select
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.workTypeId"
          >
            <Option
              v-for="item in workTypeList"
              :value="item.workTypeValue"
              :key="item.workTypeValue"
              >{{ item.workTypeName }}</Option
            >
          </Select>
        </div>
        <div class="mb-item">
          <div class="label">岗位状态：</div>
          <Select
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.status"
          >
            <Option
              v-for="item in statusList"
              :value="item.dictValue"
              :key="item.dictValue"
              >{{ item.dictLabel }}</Option
            >
          </Select>
        </div>
      </div>
      <div class="c-btnbox-item">
        <div class="mb-item">
          <div class="label">教育码：</div>
          <Select
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.codeType"
          >
            <Option
              v-for="item in codeList"
              :value="item.dictValue"
              :key="item.dictValue"
              >{{ item.dictLabel }}</Option
            >
          </Select>
        </div>
        <div class="mb-item">
          <div class="label">进场时间：</div>
          <DatePicker
            v-model="searchMainListData.entryDate"
            type="date"
            style="width: 200px; margin-right: 14px"
            placeholder="请选择进场时间"
          ></DatePicker>
        </div>
        <div class="mb-item">
          <div class="label">退场时间：</div>
          <DatePicker
            v-model="searchMainListData.exitDate"
            type="date"
            style="width: 200px; margin-right: 14px"
            placeholder="请选择退场时间"
          ></DatePicker>
        </div>
        <div class="mb-item">
          <div class="label">接入抖工：</div>
          <Select
            style="width: 200px; margin-right: 14px"
            v-model="searchMainListData.isDgtech"
          >
            <Option
              v-for="item in isDougongList"
              :value="item.dictValue"
              :key="item.dictValue"
              >{{ item.dictLabel }}</Option
            >
          </Select>
        </div>
      </div>
      <div class="c-btnbox-item" style="margin-bottom: 14px">
        <Button
          type="primary"
          style="margin-right: 14px"
          @click="handleMainListSearch"
          >搜索</Button
        >
        <Button @click="handleMainListSearch('reset')">重置</Button>
      </div>
      <div class="c-btnbox-item">
        <Button type="primary" style="margin-right: 14px" @click="exportTable"
          >导出花名册</Button
        >
        <!-- <Button
          type="primary"
          style="margin-right: 14px;background-color: #a77bff;border-color: #a77bff;"
          >导入花名册</Button
        > -->
        <Upload
          action="/"
          :name="'file'"
          :multiple="false"
          :before-upload="onBefore"
          :accept="Accept"
          :format="Format"
          :data="uploadData"
        >
          <Button
            type="primary"
            style="
              margin-right: 14px;
              background-color: #a77bff;
              border-color: #a77bff;
            "
            >导入花名册</Button
          >
        </Upload>
        <Button type="success" style="margin-right: 14px" @click="exportTable2"
          >下载花名册模板</Button
        >
        <Button type="info" style="margin-right: 14px" @click="addPeople"
          >新增人员</Button
        >
        <Button
          type="info"
          style="
            margin-right: 14px;
            background-color: #ee82ee;
            border-color: #ee82ee;
          "
          @click="uploadRosterData"
          >更新花名册数据</Button
        >
      </div>
      <Alert show-icon
        >在册总人数<span style="color: #2d8cf0;">{{totalData.totalPeople || 0}}</span>人，
        其中没上平台<span style="color: #2d8cf0;">{{totalData.notDgtechPeople || 0}}</span>人，
        已上平台<span style="color: #2d8cf0;">{{totalData.dgtechPeople || 0}}</span>人；
        已上平台中在岗人数<span style="color: #2d8cf0;">{{totalData.dutyPeople || 0}}</span>人，
        其中绿码人数<span style="color: #2d8cf0;">{{totalData.greenCode || 0}}</span>人，
        黄码人数<span style="color: #2d8cf0;">{{totalData.yellowCode || 0}}</span>人，
        红码人数<span style="color: #2d8cf0;">{{totalData.redCode || 0}}</span>人。</Alert
      >
    </div>
    <div class="second-title">
      <div class="shu" />
      花名册名单
    </div>
    <Table
      :loading="mainLoading"
      size="small"
      :columns="mainCloums"
      :data="mainList"
      :width="ScreenWidth - 350"
    >
      <!-- <template slot-scope="{ row, index }" slot="status">
        <div class="btnbox-flex">
          <div>
            {{ row.status | rectifyStatusFilters(statusList) }}
          </div>
        </div>
      </template> -->


       <template slot-scope="{ row }" slot="positioningStatus">
        <div class="btnbox-flex">
          <div>
            {{ row.positioningStatus=='ATTENDANCE_ON'?'考勤在线':row.positioningStatus=='ATTENDANCE_OFF'?'考勤离线':row.positioningStatus=='POSITIONING_OFF'?'无法获取定位':'无法获取定位' }}
          </div>
        </div>
      </template>

      <template slot-scope="{ row, index }" slot="teachCode">
        <div class="table-qrcode-img" @click="showQR(row.teachCode)">
          <img :src="row.teachCode" alt />
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <Button
          type="primary"
          style="margin-right: 8px"
          @click="editPeople(row)"
          >编辑</Button
        >
        <Button
          type="warning"
          style="margin-right: 8px"
          @click="removePeople(row)"
          v-if="row.isDgtechStr === '否'"
          >移除</Button
        >
      </template>

      <!-- 是否有违规记录 -->
      <template slot-scope="{ row }" slot="irregularStatus">
          <div style="text-align:center">
            <Button type="primary" v-if="row.irregularStatus" @click="openIrregularities(row.id)">查看</Button>
            <span v-else >无</span>
        </div>
      </template>

    </Table>
    <page
      show-total
      class="page-box"
      :page-size="mainSize"
      :total="mainTotal"
      :current="mainPage"
      @on-change="handleMainPageChange"
    />
    <!-- 新增、编辑人员 -->
    <Modal
      v-model="editPeopleFlag"
      title="编辑人员"
      @on-ok="toEditPeople"
      @on-cancel="toEditPeople('close')"
      :loading="editPeopleNext"
      :mask-closable="false"
      width="60"
    >
      <Spin v-if="editPeopleLoading" fix>
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
        <div>Loading</div>
      </Spin>

      <Tabs type="card" @click="pickTab" v-model="tabFlag">
        <TabPane label="个人信息" name="info"></TabPane>
        <TabPane
          label="班组信息"
          v-if="!addFlag && peopleInfo.isDgtech == 1"
          name="team"
        ></TabPane>
        <TabPane
          label="教育情况"
          v-if="!addFlag && peopleInfo.isDgtech == 1"
          name="education"
        ></TabPane>
      </Tabs>
      <div class="p-box">
        <div class="part" v-if="tabFlag == 'info'">
          <div class="p-title">个人信息</div>
          <div class="p-content">
            <div class="pc-part">
              <div class="pc-item">
                <div class="label">头像:</div>
                <img :src="peopleInfo.headImg" alt="" />
              </div>
            </div>
          </div>
          <div class="p-content">
            <div class="pc-part">
              <div class="pc-item">
                <div class="label l-bitian">姓名:</div>
                <Input
                  placeholder="请输入姓名"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.name"
                  :readonly="!addFlag"
                />
              </div>
              <div class="pc-item">
                <div class="label l-bitian">身份证:</div>
                <Input
                  placeholder="请输入身份证"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.idCard"
                  :readonly="!addFlag"
                />
              </div>
              <div class="pc-item">
                <div class="label l-bitian">手机号码:</div>
                <Input
                  placeholder="请输入手机号码"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.phone"
                  :readonly="!addFlag"
                />
              </div>
              <div class="pc-item">
                <div class="label l-bitian">性别:</div>
                <Select
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.sex"
                  :disabled="!addFlag"
                >
                  <Option
                    v-for="item in sexList"
                    :value="item.dictValue"
                    :key="item.dictValue"
                    >{{ item.dictLabel }}</Option
                  >
                </Select>
              </div>
              <div class="pc-item">
                <div class="label l-bitian">班组:</div>
                <Select
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.teamId"
                >
                  <Option v-for="item in teamList" :value="item.id" :key="item.id">{{
                    item.teamName
                  }}</Option>
                </Select>
              </div>
            </div>
            <div class="pc-part">
              <div class="pc-item">
                <div class="label l-bitian">生日:</div>
                <DatePicker
                  v-model="peopleInfo.birthDay"
                  :disabled="!addFlag"
                  type="date"
                  style="width: 200px; margin-right: 14px"
                  placeholder="请选择生日"
                ></DatePicker>
              </div>
              <div class="pc-item">
                <div class="label">银行卡号:</div>
                <Input
                  placeholder="请输入银行卡号"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.bankNumber"
                  :readonly="!addFlag"
                />
              </div>
              <div class="pc-item">
                <div class="label">银行名称:</div>
                <Input
                  placeholder="请输入银行名称"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.bank"
                  :readonly="!addFlag"
                />
              </div>
              <div class="pc-item">
                <div class="label">家庭住址:</div>
                <Input
                  placeholder="请输入家庭住址"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.address"
                  :readonly="!addFlag"
                />
              </div>
            </div>
          </div>
          <div class="p-title">工种</div>
          <div class="p-content">
            <div class="pc-part">
              <div class="pc-item">
                <div class="label">工种:</div>
                <Select
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.workTypeId"
                  :disabled="!addFlag"
                >
                  <Option
                    v-for="item in workTypeList"
                    :value="item.workTypeValue"
                    :key="item.workTypeValue"
                    >{{ item.workTypeName }}</Option
                  >
                </Select>
              </div>
              <div class="pc-item">
                <div class="label">是否特殊工种:</div>
                <Select
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.isSpecial"
                >
                  <Option
                    v-for="item in dgList"
                    :value="item.dictValue"
                    :key="item.dictValue"
                    >{{ item.dictLabel }}</Option
                  >
                </Select>
              </div>
              <div class="pc-item">
                <div class="label">证书编号:</div>
                <Input
                  placeholder="请输入证书编号"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.certificateNo"
                />
              </div>
            </div>
            <div class="pc-part">
              <div class="pc-item">
                <div class="label">发证机关:</div>
                <Input
                  placeholder="请输入发证机关"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.issuingAuthority"
                />
              </div>
              <div class="pc-item">
                <div class="label">发证日期:</div>
                <DatePicker
                  v-model="peopleInfo.issueDate"
                  type="date"
                  style="width: 200px; margin-right: 14px"
                  placeholder="请选择发证日期"
                ></DatePicker>
              </div>
              <div class="pc-item">
                <div class="label">有效日期:</div>
                <DatePicker
                  v-model="peopleInfo.validityDate"
                  type="date"
                  style="width: 200px; margin-right: 14px"
                  placeholder="请选择有效日期"
                ></DatePicker>
              </div>
            </div>
          </div>
          <div class="p-title">教育码</div>
          <div class="p-content">
            <div class="pc-part">
              <div class="pc-item">
                <div class="label">线下完成安全教育:</div>
                <Select
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.isOffline"
                  :disabled="!addFlag"
                >
                  <Option
                    v-for="item in dgList"
                    :value="item.dictValue"
                    :key="item.dictValue"
                    >{{ item.dictLabel }}</Option
                  >
                </Select>
              </div>
            </div>
            <div class="pc-part">
              <div class="pc-item">
                <div class="label">绿码:</div>
                <Select
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.isGreenCode"
                  :disabled="!addFlag"
                >
                  <Option
                    v-for="item in dgList"
                    :value="item.dictValue"
                    :key="item.dictValue"
                    >{{ item.dictLabel }}</Option
                  >
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div class="part" v-if="tabFlag == 'team'">
          <div class="p-title">班组信息</div>
          <div class="p-content">
            <div class="pc-part">
              <div class="pc-item">
                <div class="label">班组:</div>
                <Select
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.teamId"
                  :disabled="!addFlag"
                >
                  <Option
                    v-for="item in teamList"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.teamName }}</Option
                  >
                </Select>
              </div>
              <div class="pc-item">
                <div class="label">岗位状态:</div>
                <Select
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.status"
                >
                  <Option
                    v-for="item in statusList"
                    :value="item.dictValue"
                    :key="item.dictValue"
                    >{{ item.dictLabel }}</Option
                  >
                </Select>
              </div>
            </div>
            <div class="pc-part">
              <div class="pc-item">
                <div class="label">进场时间:</div>
                <DatePicker
                  v-model="peopleInfo.entryDate"
                  type="date"
                  style="width: 200px; margin-right: 14px"
                  placeholder="请选择进场时间"
                  :disabled="!addFlag"
                ></DatePicker>
              </div>
              <div class="pc-item">
                <div class="label">退场时间:</div>
                <DatePicker
                  v-model="peopleInfo.exitDate"
                  :disabled="!addFlag"
                  type="date"
                  style="width: 200px; margin-right: 14px"
                  placeholder="请选择退场时间"
                ></DatePicker>
              </div>
            </div>
          </div>
          <div class="p-title">其他</div>
          <div class="p-content">
            <div class="pc-part">
              <div class="pc-item">
                <div class="label">工卡:</div>
                <Input
                  placeholder="请输入工卡"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.jobCard"
                />
              </div>
              <div class="pc-item">
                <div class="label">合同编号:</div>
                <Input
                  placeholder="请输入合同编号"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.contractNo"
                />
              </div>
            </div>
            <div class="pc-part">
              <div class="pc-item">
                <div class="label">备注:</div>
                <Input
                  placeholder="请输入备注"
                  style="width: 200px; margin-right: 14px"
                  v-model="peopleInfo.remarks"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="part" v-if="tabFlag == 'education'">
          <div class="p-title">教育情况</div>
          <div class="p-content">
            <div class="pc-part">
              <div class="pc-item">
                <div class="label">教育码:</div>
                <img
                  v-if="peopleInfo.teachCode"
                  :src="peopleInfo.teachCode"
                  alt=""
                />
                <div v-else>暂无教育码</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <!-- 违规记录列表 -->
    <Modal
     class="irregularities"
      v-model="irregularitiesListModal"
      title="违规记录列表"
      :mask-closable="false"
      @on-ok="closeIrregularities"
      width='1300'        
    >
    <Card
          style="
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
          "
        >
          <!-- <div class="team-manage-box">
            <div class="c-btnbox">
                <div class="c-btnbox-item">
                  <div class="mb-item" style="display:flex;">
                    <div class="label" style="margin-top:5px">关键字：</div>
                    <Input
                      style="width: 200px; margin-right: 14px"
                      placeholder="请输入关键词搜索"
                      v-model="irregularitieListKeyword"
                    />
                  </div>
                  <div class="mb-item" style="display:flex;">
                    <div class="label" style="margin-top:5px">等级：</div>
                    <Select
                      style="width: 200px; margin-right: 14px"
                      v-model="irregularitieListIrregularLevel"
                    >

                      <Option value="MILD" key="MILD">轻微</Option>
                      <Option value="MONDERATE" key="MONDERATE">中等</Option>
                      <Option value="SEVERE" key="SEVERE">严重</Option>
                    </Select>
                  </div>
               </div>
                <div class="c-btnbox-item" style="margin-bottom: 14px">
                  <Button
                    type="primary"
                    style="margin-right: 14px"
                    @click="toSearchIrregularities"
                    >搜索</Button
                  >
                    <Button @click="clearIrregularities">重置</Button>
                  
                </div>
            </div>
          </div> -->
          
          <Table
            :columns="irregularitiesColumnsList"
            :data="irregularitiesDataList"
            :loading="irregularitiesLoading"
          >
           <template slot-scope="{ row, index }" slot="irregularLevel">
              <span>{{row.irregularLevel=='MILD'?'轻微':row.irregularLevel=='MONDERATE'?'中等':row.irregularLevel=='SEVERE'?'严重':'-'}}</span>
            </template>
            <template slot-scope="{ row, index }" slot="irregularPicture">
              <div style="display:flex;flex-wrap:wrap">
              <img @click="showQR(item)" style="width:100px;height:150px;margin:0 10px 10px 0" v-for="(item,index) in row.irregularPicture.split(';')" :key="index" :src="item" alt="">
              
              </div>

            
            </template>
            <template slot-scope="{ row, index }" slot="teamItems">
              
                <Tooltip>
                  <div slot="content">
                      <div v-for="(item,index) in row.teamItems" :key="index" >
                        {{item.username}}-{{item.teamName}}
                      </div>  
                  </div>
                  <div style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;"> 
                    <div v-for="(item,index) in row.teamItems" :key="index" >
                        {{item.username}}-{{item.teamName}}
                    </div> 
                  </div>
                </Tooltip>
              
             
            </template>
          </Table>

          <page
            show-total
            class="page-box"
            @on-change="toIrregularitiesPageChange"
            :page-size="10"
            :total="irregularitiesListTotal"
          />
        </Card>
     

    </Modal>
    <!-- 放大二维码 -->
    <div class="show-qrcode" v-show="showQRFlag" @click="hiddenQR" style="z-index:999999!important">
      <div class="qrcode-img">
        <img :src="showImgSrc" alt />
      </div>
    </div>
  </div>
</template>

<script>
import rictifyExpandRow from "../template/tableExpandRectify.vue";
import Storage from "../../../utils/localStorage";
import constants from "../../../constants/constants";
import moment from "moment"
export default {
  name: "rectifyTemp",
  data() {
    return {
      searchMainListData: {
        searchValue: "",
        teamId: "",
        workTypeId: "",
        status: "",
        codeType: "",
        entryDate: "",
        exitDate: "",
        isDgtech: "",
      },
      totalData: {},
      mainList: [],
      mainCloums: [
        // {
        //   type: "expand",
        //   width: 50,
        //   render: (h, params) => {
        //     return h(rictifyExpandRow, {
        //       props: {
        //         row: params.row,
        //       },
        //     });
        //   },
        // },
        {
          title: "姓名",
          key: "name",
          width: 80,
        },
        {
          title: "班组",
          key: "team",
          width: 140,
        },
        {
          title: "工种",
          key: "workType",
          width: 160,
        },
        {
          title: "性别",
          key: "sexStr",
          width: 80,
        },
        {
          title: "是否特殊工种",
          key: "isSpecialStr",
          width: 120,
        },
        {
          title: "家庭住址",
          key: "address",
          minWidth: 240,
        },
        {
          title: "手机号码",
          key: "phone",
          width: 120,
        },
        {
          title: "身份证号码",
          key: "idCard",
          width: 180,
        },
        {
          title: "教育码",
          slot: "teachCode",
          minWidth: 120,
        },
        {
          title: "岗位状态",
          key: "statusStr",
          width: 100,
        },
        {
          title: "考勤状态",
          key: "positioningStatus",
          slot: "positioningStatus",
          width: 100,
        },
        {
          title: "进场日期",
          key: "entryDate",
          width: 120,
        },
        {
          title: "退场时间",
          key: "exitDate",
          width: 120,
        },
        {
          title: "备注",
          key: "remarks",
          width: 120,
        },
        {
          title: "是否有违规记录",
          key: "irregularStatus",
          slot: "irregularStatus",
          width: 120,
          // align:"center",
        },
        {
          title: "是否接入抖工",
          key: "isDgtechStr",
          width: 80,
        },
        {
          title: "操作",
          slot: "action",
          width: 240,
          align: "center",
          fixed: "right",
        },
      ],
      mainLoading: false,
      mainPage: 1,
      mainSize: 15,
      mainTotal: 0,
      rangeDate: [],
      exportData: {
        date: "",
      },
      prefix: "",
      teamList: [],
      workTypeList: [],
      statusList: [
        {
          dictValue: 0,
          dictLabel: "在岗",
        },
        {
          dictValue: 1,
          dictLabel: "离岗",
        },
      ],
      isDougongList: [
        {
          dictValue: 1,
          dictLabel: "是",
        },
        {
          dictValue: 0,
          dictLabel: "否",
        },
      ],
      codeList: [
        {
          dictValue: 1,
          dictLabel: "绿码",
        },
        {
          dictValue: 2,
          dictLabel: "黄码",
        },
        {
          dictValue: 3,
          dictLabel: "红码",
        },
      ],
      dgList: [
        {
          dictValue: 0,
          dictLabel: "否",
        },
        {
          dictValue: 1,
          dictLabel: "是",
        },
      ],
      sexList: [
        // {
        //   dictValue: 0,
        //   dictLabel: "未知",
        // },
        {
          dictValue: 1,
          dictLabel: "男",
        },
        {
          dictValue: 2,
          dictLabel: "女",
        },
      ],
      showQRFlag: false,
      showImgSrc: "",
      file: null,
      editPeopleFlag: false,
      editPeopleNext: true,
      peopleInfo: {
        projectId: "", //	是	long	项目ID
        headImg: "", //	否	string	头像
        name: "", //	是	string	姓名
        birthDay: "", //	否	string	生日
        phone: "", //	是	string	手机号
        idCard: "", //	是	string	身份证号码
        sex: "", //	否	int	性别：0未知，1男，2女
        address: "", //	否	string	家庭住址
        workTypeId: "", //	否	long	工种ID
        issuingAuthority: "", //	否	string	发证机关
        isSpecial: "", //	否	int	是否特殊工种：0否，1是
        issueDate: "", //	否	string	发证日期
        certificateNo: "", //	否	string	证书编号
        validityDate: "", //	否	string	有效日期
        bank: "", //	否	string	银行名称
        bankNumber: "", //	否	string	银行卡号
        isOffline: '',
        isGreenCode: '',
        teamId: ''
      },
      tabFlag: "info",
      addFlag: true,
      Accept: ".xls,.xlsx", //上传文件格式限制
      Format: [".xls", ".xlsx"], //上传文件格式限制
      uploadData: {},
      editPeopleLoading: false,




        //违规记录
      irregularitiesColumnsList: [

      // {
      //     title: "违规人员列表",
      //     key: "teamItems",
      //     slot: "teamItems",
      //     // ellipsis:true,
      //     width: 200,
      //     align:"center",
          
      // },
      {
          title: "违规描述",
          key: "irregularDescription",
          ellipsis:true,
          align:"center",
          tooltip:true,

      },
      {
          title: "违规等级",
          key: "irregularLevel",
          slot: "irregularLevel",
          width: 100,
          ellipsis:true,
          align:"center",
          
      },
      
      {
          title: "违规备注",
          key: "irregularRemark",
          ellipsis:true,
          align:"center",
          tooltip:true,

      },
      {
          title: "违规图片",
          key: "irregularPicture",
          slot: "irregularPicture",
          // ellipsis:true,
          width:380,
          align:"center",


      },
      
      {
          title: "违规时间",
          key: "irregularTime",
          width: 130,
          align:"center",


      },
      {
          title: "更新时间",
          key: "updateTime",
          width: 180,
          align:"center",

      },

      ],

      irregularitiesDataList: [],//违规记录列


      irregularitiesListModal:false,//弹窗显示违规记录列表
      irregularitiesLoading:false,//违规记录列表加载中

      irregularitiesListTotal: 0,//违规记录的总数

      irregularitieListPageNo: 1,//违规记录的分页
      irregularitieListPageSize: 10,//违规记录的分页
      // irregularitieListKeyword: '',//违规记录的keyword
      // irregularitieListIrregularLevel: " ",//违规记录的违规等级查询
    };
  },
  props: ["ProjectInfo", "ScreenWidth"],
  created() {},
  mounted() {
    this.getAboutProjectWorktypeList();
    this.getFilesTeamList();
    this.handleMainListSearch();
  },
  filters: {
    rectifyStatusFilters(sid, list) {
      let newVal = "";
      list.forEach((val) => {
        if (sid == val.dictValue) {
          newVal = val.dictLabel;
        }
      });
      return newVal;
    },
  },
  watch: {},
  methods: {
      // 清除违规记录
    clearIrregularities() {
      
        this.irregularitieListPageNo = 1;
        // this.irregularitieListKeyword = "";
        // this.irregularitieListIrregularLevel = "";
        this.getirregularitiesList();
      
    },
     // 搜索违规记录
    toSearchIrregularities() {
      this.irregularitieListPageNo = 1;
      this.irregularitiesLoading = true;
      // this.irregularitieListIrregularLevel==undefined?this.irregularitieListIrregularLevel="":this.irregularitieListIrregularLevel;
      this.getirregularitiesList();
    },
    closeIrregularities(){
      this.irregularitiesListModal=false;
      this.irregularitiesLoading=false;      
    },
    //查看违规记录列表
    openIrregularities(data){
      this.rosterId=data;
      this.irregularitiesListModal=true;
      this.irregularitiesLoading=true;
      this.getirregularitiesList();
    },
    // 获取违规记录列表
    async getirregularitiesList() {
      const storage = new Storage();
      const CONSTANTS = constants;
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
      if (userInfo) {



      // &keyword=${this.irregularitieListKeyword}&irregularLevel=${this.irregularitieListIrregularLevel}
      let res = await this.$axios.get(
          `https://api.dougongapp.com/dgtech/api/mgmt/rosters/${this.rosterId}/irregularities?pageNo=${this.irregularitieListPageNo}&pageSize=${this.irregularitieListPageSize}`,
          { headers: { accessToken: token.accessToken } }
        );
        if (res) {
          console.log(res);
          this.irregularitiesLoading = false;
          this.irregularitiesDataList = res.data.resultData.dataList || [];
          this.irregularitiesListTotal=res.data.resultData.totalNum;
        }
      } else {
        this.$router.push({ name: "login" });
      }
    },
     // 违规记录列表管理-点击分页处理
    toIrregularitiesPageChange(e) {
      this.irregularitieListPageNo = e;
      this.irregularitiesLoading = true;
      this.getirregularitiesList();
    },
    // 获取主表
    getMainList() {
      this.mainLoading = true;
      let data = {
        pageSize: this.mainSize,
        pageNum: this.mainPage,
        projectId: this.ProjectInfo.id,
        searchValue: this.searchMainListData.searchValue, //否	string	关键字
        teamId: this.searchMainListData.teamId, //否	long	班组ID
        workTypeId: this.searchMainListData.workTypeId, //否	long	工种ID
        status: this.searchMainListData.status, //否	int	岗位状态：0在岗，1离岗
        codeType: this.searchMainListData.codeType, //否	int	教育码：1绿码、2黄码、3红码
        entryDate: this.searchMainListData.entryDate, //否	string	进场日期
        exitDate: this.searchMainListData.exitDate, //否	string	退出日期
        isDgtech: this.searchMainListData.isDgtech, //否	int	是否接入抖工：0否，1是
      };
      this.$fetch(`/osapi/roster/list`, {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          this.mainTotal = res.total;
          let list = res.resList;
          this.totalData = res.data;
          this.mainList = list;
          this.prefix = res.prefix;
          this.mainLoading = false;

        if(res.resList.length==0){
            this.mainCloums = this.mainCloums.filter(item => item.key !== 'positioningStatus') // 控制是否开启监控列是否展示
        }

        if(res.resList.length!==0){
          if ((!res.resList[0].attendanceStatusService)  || (!res.resList[0].mobileAttendanceSwitch)) {
            // 只要有一个条件不满足(为false)  则不展示 是否开启监控列
            this.mainCloums = this.mainCloums.filter(item => item.key !== 'positioningStatus') // 控制是否开启监控列是否展示
          }
         }
          
        } else {
          this.mainLoading = false;
          this.$Message.info("查询整改列表失败，请稍后重试");
        }
      });
    },
    // 主表搜索
    handleMainListSearch(type) {
      if (type == "reset") {
        this.searchMainListData = {
          searchValue: "",
          teamId: "",
          workTypeId: "",
          status: "",
          codeType: "",
          entryDate: "",
          exitDate: "",
          isDgtech: "",
        };
        this.rangeDate = [];
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      this.handleMainListSearch();
    },
    handelRangeDateChange(e) {
      var date1 = new Date(e[0]);
      var date2 = new Date(e[1]);
    },
    hiddenQR() {
      this.showQRFlag = !this.showQRFlag;
    },
    showQR(e) {
      if (!e) {
        return false;
      }
      this.showQRFlag = !this.showQRFlag;
      this.showImgSrc = e;
    },
    exportTable() {
      let data = {
        projectId: this.ProjectInfo.id,
        searchValue: this.searchMainListData.searchValue,
        teamId: this.searchMainListData.teamId,
        workTypeId: this.searchMainListData.workTypeId,
        status: this.searchMainListData.status,
        codeType: this.searchMainListData.codeType,
        entryDate: this.searchMainListData.entryDate,
        exitDate: this.searchMainListData.exitDate,
        isDgtech: this.searchMainListData.isDgtech,
      };
      this.$axios
        .post(`${this.apiUrlCros}/osapi/roster/export`, data)
        .then((res) => {
          window.open(res.data.msg, "_blank");
        })
        .catch((err) => {
         // this.$Message.error(err);
        });
    },
    exportTable2() {
      let data = {};
      this.$axios
        .get(`${this.apiUrlCros}/osapi/roster/importTemplate`, data)
        .then((res) => {
          // let blob = res.data;
          // let reader = new FileReader();
          // reader.readAsDataURL(blob);
          // reader.onload = (e) => {
          //   let a = document.createElement("a");
          //   a.download = `花名册.xlsx`;
          //   a.href = e.target.result;
          //   document.body.appendChild(a);
          //   a.click();
          //   document.body.removeChild(a);
          // };
          window.open(res.data.msg, "_blank");
        })
        .catch((err) => {
         // this.$Message.error(err);
        });
    },
    onBefore(file) {
      this.file = file;
      this.sendRosterList();
      return false;
    },
    sendRosterList() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("updateSupport", true);
      formData.append("projectId", this.ProjectInfo.id);
      this.$fetch("/osapi/roster/importData", {
        method: "post",
        data: formData,
        headers: {
          //添加请求头
          "Content-Type":
            "multipart/form-data;boundary = " + new Date().getTime(),
        },
      })
        .then((res) => {
          if (res.code == "200") {
            this.file = null;
            this.handleMainListSearch("reset");
            this.$Message.success("导入成功");
          } else {
            this.file = null;
            this.$Message.error("导入失败，稍后再试");
          }
        })
        .catch((err) => {
          //this.$Message.error(err);
          this.file = null;
          
        });
    },
    addPeople() {
      this.peopleInfo = {
        projectId: "",
        headImg: "",
        name: "",
        birthDay: "",
        phone: "",
        idCard: "",
        sex: "",
        address: "",
        workTypeId: "",
        issuingAuthority: "",
        isSpecial: "",
        issueDate: "",
        certificateNo: "",
        validityDate: "",
        bank: "",
        bankNumber: "",
        isOffline: '',
        isGreenCode: '',
        teamId: ''
      };
      this.addFlag = true;
      this.editPeopleFlag = true;
    },
    editPeople(row) {
      // this.peopleInfo = row
      this.addFlag = false;
      this.editPeopleFlag = true;
      this.getPeopleDetail(row.id);
    },
    toEditPeople(type) {
      if (type == "close") {
        this.editPeopleFlag = false;
        return false;
      }
      if (this.addFlag) {
        this.addFun();
      } else {
        this.editFun();
      }
    },
    pickTab(e) {},
    addFun() {
      let ndata = this.peopleInfo;
      if (ndata.name == "" || ndata.idCard == "" || ndata.phone == "" || ndata.birthDay == "" || ndata.sex == "" || ndata.teamId == ""  ) {
        this.$Message.info("请填写完整资料");
        this.editPeopleNext = false;
        setTimeout(() => {
          this.editPeopleNext = true;
        }, 500);
        return false;
      }
      var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if(!p.test(ndata.idCard)){
        this.$Message.info("请填写正确身份证");
        this.editPeopleNext = false;
        setTimeout(() => {
          this.editPeopleNext = true;
        }, 500);
        return false
      }
      var myreg=/^[1][3,4,5,7,8][0-9]{9}$/;
      if(!myreg.test(ndata.phone)){
        this.$Message.info("请填写正确手机号");
        this.editPeopleNext = false;
        setTimeout(() => {
          this.editPeopleNext = true;
        }, 500);
        return false
      }
      let data = {
        projectId: this.ProjectInfo.id,
        headImg: ndata.headImg,
        name: ndata.name,
        birthDay: moment(ndata.birthDay).format("YYYY-MM-DD"),
        phone: ndata.phone,
        idCard: ndata.idCard,
        sex: ndata.sex,
        address: ndata.address,
        workTypeId: ndata.workTypeId,
        workType: ndata.workTypeId==""?"": 
          this.workTypeList[
            this.workTypeList.findIndex(
              (o) => o.workTypeValue == ndata.workTypeId
            )
          ].workTypeName,
        issuingAuthority: ndata.issuingAuthority,
        isSpecial: ndata.isSpecial,
        issueDate: ndata.issueDate,
        certificateNo: ndata.certificateNo,
        validityDate: ndata.validityDate,
        bank: ndata.bank,
        bankNumber: ndata.bankNumber,
        isOffline: ndata.isOffline,
        isGreenCode: ndata.isGreenCode,
        teamId: this.peopleInfo.teamId
      };
      this.$fetch("/osapi/roster/add", {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code == "200") {
          this.$Message.success("操作成功");
          this.handleMainListSearch("reset");
          this.editPeopleFlag = false;
        } else {
          this.editPeopleNext = false;
          setTimeout(() => {
            this.editPeopleNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    editFun() {
      let ndata = this.peopleInfo;
      this.$fetch("/osapi/roster/edit", {
        method: "post",
        data: ndata,
      }).then((res) => {
        if (res && res.code == "200") {
          this.$Message.success("操作成功");
          this.handleMainListSearch("reset");
          this.editPeopleFlag = false;
        } else {
          this.editPeopleNext = false;
          setTimeout(() => {
            this.editPeopleNext = true;
          }, 500);
          this.$Message.error("操作失败");
        }
      });
    },
    removePeople(val) {
      let data = {
        ids: val.id,
      };
      this.$Modal.confirm({
        title: "提示",
        content: "<p>是否移除该人员</p>",
        onOk: () => {
          this.$fetch(`/osapi/roster/remove`, {
            method: "post",
            data,
          }).then((res) => {
            if (res.code == "200") {
              this.$Message.success("操作成功");
              this.handleMainListSearch("reset");
            } else {
              this.$Message.info("操作失败");
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 获取项目关联当前工种
    getAboutProjectWorktypeList() {
      let that = this;
      let data = {};
      this.$fetch(
        `/sapi/workType/relate/getProjectRelates?projectId=${this.ProjectInfo.id}`,
        {
          method: "get",
          data,
        }
      )
        .then((res) => {
          console.log(res);
          if (res.code == "200") {
            if (res.data.length > 0) {
              this.workTypeList = res.data;
            } else {
              this.$Message.info("该项目没有关联工种");
            }
          } else {
            this.$Message.error("获取工种失败");
          }
        })
        .catch((err) => {
         // this.$Message.error("获取工种失败");
        });
    },
    // 获取人员信息
    getPeopleDetail(val) {
      let that = this;
      this.editPeopleLoading = true;
      let data = {
        id: val,
      };
      this.$fetch(`/osapi/roster/details`, {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.editPeopleLoading = false;
            let pdata = res.data;
            this.peopleInfo = pdata;
          } else {
            this.$Message.error("获取人员信息失败");
            this.editPeopleFlag = false;
          }
        })
        .catch((err) => {
         // this.$Message.error("获取人员信息失败");
          this.editPeopleFlag = false;
        });
    },
    // 获取班组列表
    getFilesTeamList() {
      let data = {
        pageSize: "1000",
        projectId: this.ProjectInfo.id,
      };
      let res = this.$fetch(`/sapi/team/getTeamsByProjectIdOrName`, {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          // this.handleFilesTeamList(res.data)
          this.teamList = res.data;
        } else {
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 更新
    uploadRosterData(){
      let data = {
        projectId: this.ProjectInfo.id
      }
      this.$axios(`${this.apiUrlCros}/osapi/roster/updateRoster`, {
        method: "post",
        data,
      }).then(res=>{})
      this.$Message.info('更新成功')
      this.handleMainListSearch('reset')
    }
  },
  destory() {},
};
</script>

<style lang="less" scoped>
@import "../public/style.less";
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
.p-box {
  .part {
    .p-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 7px;
    }
    .p-content {
      padding-left: 14px;
      display: flex;
      flex-wrap: wrap;
      .pc-part {
        flex: 1;
        .pc-item {
          margin-bottom: 18px;
          .label {
            margin-bottom: 4px;
          }
          .l-bitian {
            position: relative;
          }
          .l-bitian::before {
            content: "*";
            position: absolute;
            left: -7px;
            top: 0px;
            color: red;
          }
          img {
            width: 120px;
            height: auto;
          }
        }
      }
    }
  }
}

.irregularities /deep/ .ivu-modal-wrap .ivu-modal-footer .ivu-btn-text{
  display: none!important;
}

</style>
