<template>
  <div class="full-content-box">
      <div class="second-title">
        <div class="shu" />
        档案管理
      </div>
      <div class="c-btnbox">
    <mycollapse>
      <div v-show="mainActive" class="collapsebox">
        <div class="c-btnbox-item">
          <div class="mb-item">
            <div class="label">班组名称：</div>
            <Input
              v-model="searchMainListData.name"
              placeholder="请输入班组名称"
              style="width: 200px; margin-right: 14px"
            />
          </div>
          <div class="mb-item">
            <div class="label">负责人姓名：</div>
            <Input
              v-model="searchMainListData.people"
              placeholder="请输入负责人姓名"
              style="width: 200px; margin-right: 14px"
            />
          </div>
          <div class="mb-item">
            <div class="label">班组名称：</div>
            <Input
              v-model="searchMainListData.teamName"
              placeholder="请输入班组名称"
              style="width: 200px; margin-right: 14px"
            />
          </div>
          <div class="mb-item">
            <div class="label">班组状态：</div>
            <Select
              v-model="searchMainListData.type"
              style="width: 200px; margin-right: 14px"
            >
              <Option
                v-for="item in teamStatusList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="c-btnbox-item" style="margin-bottom: 14px">
          <Button
            type="primary"
            @click="handleMainListSearch"
            style="margin-right: 14px"
            >搜索</Button
          >
          <Button @click="handleMainListSearch('reset')">重置</Button>
        </div>
        <Table
          highlight-row
          ref="mainTable"
          :columns="mainCloums"
          :data="mainList"
          @on-current-change="handleMainListPick"
          :loading="mainLoading"
        >
          <template slot-scope="{ row, index }" slot="action">
            <!-- <Button type="primary" style="margin-right: 8px">在线预览</Button>
            <Button type="primary">下载</Button> -->
          </template>
        </Table>
        <div class="fun-page-box">
          <Button @click="handleClearMain" style="margin-right: 14px"
            >清除列表选中</Button
          >
          <page show-total
            :total="mainTotal"
            :page-size="mainSize"
            @on-change="handleMainPageChange"
            :current="mainPage"
          />
        </div>
      </div>
    </mycollapse>
    <div class="close-box" @click="showOrClose('main')">
      <div class="center-content">
        <span>{{ mainActive ? "收起" : "展开" }}</span>
        <Icon :type="mainActive ? 'md-arrow-dropup' : 'md-arrow-dropdown'" />
      </div>
    </div>
        <!-- <Divider /> -->
        <Tabs type="card" @on-click="pickTab" v-model="tabFlag">
          <TabPane
            v-for="(item, index) in tabList"
            :key="index"
            :label="item.tabLabel"
            :name="item.tabName"
          ></TabPane>
        </Tabs>
        <!-- 子表-班组档案 -->
        <div class="tab-content-box" v-if="tabFlag == 'files'">
          <div class="tab-nullbox" v-if="!mainListPickFlag">
            <div class="nulltips">
              {{ tabDataNull ? "暂无数据" : "请先选择任务" }}
            </div>
          </div>
          <div v-else>
            <div class="tcb-item">
              <div class="tcbi-item">
                <Button type="primary" style="margin-right: 14px"
                    @click="toOpenQZ('team')"
                  >班组集体签字表</Button
                >
                <Button disabled>班组档案批量下载</Button>
              </div>
            </div>
            <div class="tcb-item">
              <div class="tcbi-item">
                <div class="label">姓名：</div>
                <Input
                  v-model="searchSubListData.name"
                  placeholder="请输入搜索人员姓名"
                  style="width: 200px"
                />
              </div>
              <div class="tcbi-item">
                <div class="label">手机号码：</div>
                <Input
                  v-model="searchSubListData.phone"
                  placeholder="请输入搜索人员手机号码"
                  style="width: 200px"
                />
              </div>
              <div class="tcbi-item">
                <div class="label">身份证：</div>
                <Input
                  v-model="searchSubListData.idcard"
                  placeholder="请输入搜索人员身份证号"
                  style="width: 200px"
                />
              </div>
              <div class="tcbi-item">
                <div class="label">在职状态：</div>
                <Select v-model="searchSubListData.status" style="width: 150px">
                  <Option
                    v-for="item in statusList"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </div>
            </div>
            <div class="tcb-item tcb-item-spac" style="margin-bottom: 14px">
              <div>
                <Button
                  type="primary"
                  style="margin-right: 14px"
                  @click="handleSearchSubFilesList"
                  >搜索</Button
                >
                <Button @click="handleSearchSubFilesList('reset')">重置</Button>
              </div>
              
              <Button
                type="info"
                    @click="toShowDownZ"
                >一键落章</Button
              >
            </div>
            <Table
              :columns="subFilesCloums"
              :data="subFilesList"
              :loading="subFilesLoading"
              :width="ScreenWidth - 350"
              size="small"
            >
              <template slot-scope="{ row, index }" slot="faceidImage">
                  <div class="head-img" @click="showQR(row.faceidImage)">
                    <img v-if="row.faceidImage" :src="row.faceidImage" alt />
                    <img
                      v-else
                      src="../../../assets/images/test_head_icon.png"
                      alt
                    />
                  </div>
                </template>
              <template slot-scope="{ row, index }" slot="action">
                <Button
                  type="primary"
                  style="margin-right: 8px"
                  @click="toOpenQZ('self', row)"
                  >个人签字表</Button
                >
              </template>
            </Table>
            <page show-total
              :total="subFilesTotal"
              :page-size="subFilesSize"
              :current="subFilesPage"
              @on-change="handleSubFilesPageChange"
              style="margin-top: 14px"
            />
          </div>
        </div>
      </div>
    <!-- 签字表--班组 -->
    <Modal
      :mask-closable="false"
      v-model="signatureFormFlag"
      :title="'签字表'"
      @on-ok="handleSignatureForm"
      @on-cancel="handleSignatureForm('cancel')"
      width="70"
    >
      <div class="modal-btnbox">
        <!-- <Button type="primary">上传档案</Button> -->
        <Button type="primary" @click="toBatchDown">批量下载</Button>
      </div>
      <Table
        :columns="filesQZCloumsList"
        :data="filesQZDataList"
        :loading="filesQZListLoading"
      >
        <template slot-scope="{ row, index }" slot="action">
          <Button
            type="primary"
            style="margin-right: 8px"
            @click="toViewFile(row.url)"
            >在线预览</Button
          >
          <Button type="primary" @click="toViewFile(row.path)">下载</Button>
        </template>
      </Table>
      <page show-total
        :total="filesQZListTotal"
        :page-size="filesQZListSize"
        @on-change="filesQZListPageChange"
        :current="filesQZListPage"
      />
    </Modal>
    <!-- 一键落章--选择日期 -->
    <Modal
      v-model="downZFlag"
      title="一键落章"
      :mask-closable="false"
      @on-ok="toDownZ"
      @on-cancel=""
      :loading="downZNext"
    >
      <div class="aa-item">
        <div class="label bitian">落章时间：</div>
        <DatePicker
          @on-change="changeZDate"
          v-model="zData"
          type="date"
          placeholder="选择落章时间"
          style="width: 200px"
        ></DatePicker>
      </div>
    </Modal>
    <!-- 放大二维码 -->
    <div class="show-qrcode" v-show="showQRFlag" @click="hiddenQR">
      <div class="qrcode-img">
        <img :src="showImgSrc" alt />
      </div>
    </div>
  </div>
</template>

<script>
import mycollapse from "../public/collapse";
export default {
  name: "MainSubTable",
  components: {
    mycollapse,
  },
  data() {
    return {
      mainActive: true,
      searchMainListData: {
        name: "",
      },
      mainList: [],
      mainCloums: [
        {
          title: "班组名称",
          key: "teamName",
        },
        {
          title: "负责人姓名",
          key: "teamLeaderName",
        },
        {
          title: "负责人手机号",
          key: "teamLeaderPhone",
        },
        {
          title: "班组状态",
          key: "statusStr",
        },
        {
          title: "操作",
          slot: "action",
        },
      ],
      mainLoading: false,
      mainPage: 1,
      mainSize: 5,
      mainTotal: 0,
      mainListPickFlag: false,
      tabDataNull: false,
      tabFlag: "files",
      tabList: [
        {
          tabLabel: "班组档案",
          tabName: "files",
        },
      ],
      searchSubListData: {
        name: "",
        phone: "",
        idcard: "",
        status: "",
      },
      statusList: [
        {
          value: "0",
          label: "在职",
        },
        {
          value: "1",
          label: "离岗",
        },
      ],
      subFilesList: [],
      subFilesCloums: [
        {
          title: "姓名",
          key: "vname",
          width: 100,
        },
        {
          title: "头像",
          slot: "faceidImage",
          width: 120,
        },
        {
          title: "工种",
          key: "workType",
          minWidth: 100,
        },
        {
          title: "手机号码",
          key: "phone",
          minWidth: 120,
        },
        {
          title: "身份证",
          key: "vidcard",
          minWidth: 200,
        },
        {
          title: "在职状态",
          key: "statusStr",
          minWidth: 100,
        },
        {
          title: "完成“入场安全须知”任务包时间",
          key: "a",
          width: 240,
        },
        {
          title: "完成“公司级安全教育”任务包时间",
          key: "a",
          width: 240,
        },
        {
          title: "完成“项目级安全教育”任务包时间",
          key: "a",
          width: 240,
        },
        {
          title: "完成“班组级安全教育”任务包时间",
          key: "a",
          width: 240,
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
          fixed: "right",
        },
      ],
      subFilesLoading: false,
      subFilesPage: 1,
      subFilesSize: 10,
      subFilesTotal: 0,
      teamStatusList: [
        {
          value: "0",
          label: "正常",
        },
        {
          value: "1",
          label: "禁止进入",
        },
        {
          value: "2",
          label: "班组已退场",
        },
      ],
      currentMainData: {},
      showQRFlag: false,
      showImgSrc: '',
      filesOnWhere: "",
      signatureFormFlag: false,
      filesQZCloumsList: [
        {
          title: "文件类型",
          key: "categoryStr",
        },
        {
          title: "文件名称",
          key: "name",
        },
        {
          title: "文件格式",
          key: "ext",
        },
        {
          title: "文件大小（KB）",
          key: "size",
        },
        {
          title: "时间",
          key: "createTime",
        },
        // {
        //   title: "文件来源",
        //   key: "f",
        // },
        {
          title: "操作",
          slot: "action",
          width: 200,
          align: "center",
        },
      ],
      filesQZDataList: [],
      filesQZListPage: 1,
      filesQZListTotal: 0,
      filesQZListSize: 15,
      filesQZListLoading: false,
      QZTitle: "",
      QZType: "",
      QZItemId: "",
      downZFlag: false,
      downZNext: true,
      zData: ''
    };
  },
  props: ["ProjectInfo","ScreenWidth"],
  created() {},
  mounted() {
    this.handleMainListSearch("reset");
  },
  filters: {},
  watch: {},
  methods: {
    // 获取主表
    getMainList() {
      this.mainLoading = true;
      let data = {
        pageSize: this.mainSize,
        pageNumber: this.mainPage,
        projectId: this.ProjectInfo.id,
        name: this.searchMainListData.name,
      };
      this.$fetch("/sapi/team/getTeamsByProjectIdOrName", {
        method: "post",
        data,
      }).then((res) => {
        if (res.code == "200") {
          this.mainList = res.data;
          this.mainTotal = res.total;
          this.mainLoading = false;
        } else {
          this.mainLoading = false;
          this.$Message.info("查询班组失败，请稍后重试");
        }
      });
    },
    // 主表搜索
    handleMainListSearch(type) {
      this.handleClearMain()
      if (type == "reset") {
        this.searchMainListData = {
          name: "",
        };
        this.mainPage = 1;
      }
      this.getMainList();
    },
    // 选中主表
    handleMainListPick(currentRow, oldCurrentRow) {
      if (currentRow) {
        this.currentMainData = currentRow;
        this.mainListPickFlag = true;
        this.handleSearchSubFilesList("reset");
      }
    },
    // 清除主表选择
    handleClearMain() {
      this.$refs.mainTable.clearCurrentRow();
      this.mainListPickFlag = false;
    },
    // 主表修改页码
    handleMainPageChange(e) {
      this.mainPage = e;
      this.handleMainListSearch()
    },
    // 子表tab选择
    pickTab(e) {},
    // 子表搜索
    handleSearchSubFilesList(type) {
      if (type == "reset") {
        this.searchSubListData = {
        name: "",
        phone: "",
        idcard: "",
        status: "",
      }
        this.subFilesPage = 1;
      }
      this.getSubListFiles();
    },
    handleSubFilesPageChange(e) {
      this.subFilesPage = e;
      this.handleSearchSubFilesList();
    },
    getSubListFiles() {
      this.subFilesLoading = true;
      let data = {
        pageNumber: this.subFilesPage,
        pageSize: this.subFilesSize,
        teamId: this.currentMainData.id,
        isRemoved: this.searchSubListData.status,
        phone: this.searchSubListData.phone,
        vidcard: this.searchSubListData.idcard,
        realname: this.searchSubListData.name,
      };
      console.log(data)
      this.$fetch(`/sapi/teamItem/getTeamItems`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.subFilesTotal = res.total;
          this.subFilesList = res.data;
          this.subFilesLoading = false;
        } else {
          this.subFilesLoading = false;
          this.$Message.error("获取班组成员列表失败");
        }
      });
    },
    toViewFile(src) {
      window.open(src, "_blank");
    },
    toDownFiles() {
      let downUrl = "";
      downUrl = this.common.openUrl(
        "8082",
        "sapi",
        `/trainingData/downloadTeamTrainData/${this.currentMainData.id}`
      );
      window.open(downUrl, "_blank");
    },
    showQR(e) {
      if (!e) {
        return false;
      }
      this.showQRFlag = !this.showQRFlag;
      this.showImgSrc = e;
    },
    hiddenQR() {
      this.showQRFlag = !this.showQRFlag;
    },
    toOpenQZ(type, val) {
      this.filesOnWhere = type;
      this.filesQZListPage = 1;
      this.signatureFormFlag = true;
      if (type == "team") {
        // // console.log(this.currentFilesTeamData)
        this.QZTitle = "班组签字表";
        this.getFilesQZListTeam();
      }
      if (type == "self") {
        this.QZTitle = "个人签字表";
        this.QZItemId = val.id;
        this.getFilesQZListTeamItem();
      }
    },
    getFilesQZListTeam() {
      this.filesQZListLoading = true;
      let data = {
        teamId: this.currentMainData.id,
        type: 13,
      };
      this.$fetch(`/sapi/trainingData/list`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.filesQZDataList = res.data;
          this.filesQZListTotal = res.total;
          this.filesQZListLoading = false;
        } else {
          this.$Message.error("获签字列表失败");
          this.filesQZListLoading = false;
        }
      });
    },
    getFilesQZListTeamItem() {
      this.filesQZListLoading = true;
      let data = {
        teamId: this.currentMainData.id,
        type: 13,
        teamItemId: this.QZItemId,
      };
      this.$fetch(`/sapi/trainingData/list`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.filesQZDataList = res.data;
          this.filesQZListTotal = res.total;
          this.filesQZListLoading = false;
        } else {
          this.$Message.error("获签字列表失败");
          this.filesQZListLoading = false;
        }
      });
    },
    filesQZListPageChange(e) {
      this.filesQZListPage = e;
      if (this.QZType == "team") {
        this.getFilesQZListTeam();
      } else {
        this.getFilesQZListTeamItem();
      }
    },
    handleSignatureForm() {},
    toBatchDown() {
      let downUrl = "";
      if (this.filesOnWhere == "team") {
        downUrl = this.common.openUrl(
          "8082",
          "sapi",
          `/trainingData/downloadTeamTrainData/${this.currentMainData.id}`
        );
      }
      if (this.filesOnWhere == "self") {
        downUrl = this.common.openUrl(
          "8082",
          "sapi",
          `/trainingData/downloadTeamItemTrainData/${this.QZItemId}`
        );
      }
      window.open(downUrl, "_blank");
    },
    toShowDownZ() {
      this.zData = "";
      this.downZFlag = true;
    },
    toDownZ() {
      if (this.zData == "") {
        this.$Message.info("请先选择落章时间");
        this.downZNext = false;
        setTimeout(() => {
          this.downZNext = true;
        }, 500);
        return false;
      }
      let data = {
        projectId: this.ProjectInfo.id,
        teamId: this.currentMainData.id,
        fileType: "13",
        signBaseDate: this.zData,
      };
      this.$fetch(`/sapi/signManager/fallingChapter`, {
        method: "post",
        data,
      }).then((res) => {
        if (res && res.code === "200") {
          this.$Message.success("操作成功");
          this.handleSearchSubFilesList("reset");
          this.downZFlag = false;
          this.downZNext = true;
        } else {
          this.downZNext = false;
          setTimeout(() => {
            this.downZNext = true;
          }, 500);
          this.$Message.error(res.msg || "操作失败");
        }
      });
    },
    changeZDate(e) {
      this.zData = e;
    },
    showOrClose(type) {
      if (type == "main") {
        this.mainActive = !this.mainActive;
      }
    },
  },
  destory() {},
};
</script>

<style lang="less" scoped>
@import "../public/style.less";
.c-btnbox-item {
  display: flex;
  flex-wrap: wrap;
  .mb-item {
    margin-bottom: 14px;
  }
}
.tcb-item-spac{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcb-item {
  display: flex;
  flex-wrap: wrap;
  .tcbi-item {
    margin-bottom: 14px;
  }
}
</style>