import Vue from "vue";
import Router from "vue-router";
import axios from 'axios'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from 'vue-lazyload';
// import AMap from 'AMap'
// Vue.use(AMap)


import common from "./utils/common"
import Storage from "./utils/localStorage"
import constants from "@/constants/constants"

import ViewUI from 'view-design'

// import style
import 'view-design/dist/styles/iview.css';
import VueFullPage from 'vue-fullpage.js'


// import '@assets/styles/public.css'
import '@assets/styles/iconfont.styl'
import '@assets/styles/common.styl'
import '@assets/styles/transition.styl'
import '@assets/styles/variable.styl';
import axs from "./utils/fetch"


// 挂载toPdf
import htmlToPdf from './views/testPaper/toPdf.js'
import animated from 'animate.css'
Vue.use(htmlToPdf)
Vue.use(animated)
// 挂载播放器
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
// 挂载swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
// require('swiper/css/swiper.css')
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper);
// 挂载element组件
import { Table,TableColumn,Progress,Checkbox, Cascader,Select, Dialog, Icon, Upload, Radio, RadioGroup, RadioButton, Option, Switch } from 'element-ui';
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Progress)
Vue.use(Checkbox)
Vue.use(Cascader)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Switch)
// 挂载视频上传
import uploader from 'vue-simple-uploader'
Vue.use(uploader)
// import 'vue-fullpage/vue-fullpage.css'
// import VueFullpage from 'vue-fullpage'
// Vue.use(VueFullpage)

Vue.config.productionTip = false

Vue.use(router)
Vue.use(ViewUI)
Vue.use(VueFullPage)
// Vue.use(VueLazyload)
// or with options
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/images/logo_1080.png'),
  loading: require('./assets/images/loading.svg'),
  attempt: 1
})

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.prototype.$axios = axios
Vue.prototype.common = common;
Vue.prototype.storage = new Storage();
Vue.prototype.$fetch = axs.fetch;
Vue.prototype.CONSTANTS = constants;
// Vue.prototype.apiUrlCros = 'https://test.api.dougongapp.com';//测试
// Vue.prototype.apiUrlCros = 'https://dougongapp.com';//正式(旧的正式,有的用这,有的用其他地方的)
Vue.prototype.apiUrlCros = 'https://api.dougongapp.com';//最新正式(统一接口)


new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    // document.dispatchEvent(new Event("render-event"));
  }
}).$mount("#app");