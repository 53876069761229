<template>
  <div>
    <div class="dashboard-container">
      <header class="header-nav-container">
        <div class="header-nav-box flexed">
          <div class="header-logo" @click="returnHome">
            <img class="logo" src="../assets/images/logo.png" />
          </div>
          <!-- 项目管理-企业列表 -->

          <!-- 判断是否是超级超级管理员  如果是的话只展示 用户列表 -->
          <template v-if="userData.maxRoleType=='ADMINISTRATOR'">
            <Menu
              class="main-top"
              mode="horizontal"
              :active-name="$route.name"
              @on-select="handleSelect"
            >
              <Submenu name="" style="float: right; max-height: 59px">
                <template slot="title">
                  <label style="cursor: pointer">
                    <Icon type="md-contact" style="font-size: 28px" />
                    {{ userName }}
                  </label>
                </template>
                <MenuItem name="resetPassword">重置密码</MenuItem>
                <MenuItem name="logout" @click="logout">退出登录</MenuItem>
              </Submenu>
            </Menu>
          </template>

          <template v-else>
            <div class="corp-box" v-if="this.$route.name == 'myProject' || this.$route.name == 'myConsole'">
            {{ userData.vname }} 当前所在企业：
            <Dropdown trigger="click" style="cursor:pointer;padding: 14px 0;">
              <div style="color: #2db7f5;">
                {{ currentCorpName }}
                <Icon type="ios-arrow-down"></Icon>
              </div>
              <DropdownMenu slot="list">
                <DropdownItem v-for="(item,index) in corpList" :key="index" @click.native="changeCorp(item)">
                  {{item.corpName}}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <Menu
            class="main-top"
            mode="horizontal"
            :active-name="$route.name"
            @on-select="handleSelect"
          >
            <Submenu name="" style="float: right; max-height: 59px">
              <template slot="title">
                <label style="cursor: pointer">
                  <Icon type="md-contact" style="font-size: 28px" />
                  {{ userName }}
                </label>
              </template>
              <!-- <MenuItem name="setting">账户设置</MenuItem> -->
              <MenuItem name="resetPassword">重置密码</MenuItem>
              <MenuItem name="logout" @click="logout">退出登录</MenuItem>
            </Submenu>
            <MenuItem
              name=""
              v-if="this.$route.name == 'newConsole' ? false : true"
              style="float: right; margin-right: -20px"
            >
              | &nbsp;
            </MenuItem>
            <MenuItem
              name="myConsole"
              v-if="this.$route.name == 'newConsole' ? false : true"
              v-show="showHeader"
              >控制台</MenuItem
            >
            <MenuItem
              name="myProject"
              v-if="this.$route.name == 'newConsole' ? false : true"
              v-show="showHeader"
              >我的项目</MenuItem
            >
            <!-- <MenuItem name="orderList" v-show="showHeader">我的账单</MenuItem> -->
            <!-- <MenuItem name="statistics" v-show="showHeader" v-if="dgKey < 3">培训统计</MenuItem> -->
          </Menu>

          </template>
          
        
        </div>
      </header>
      <section class="ds-main-container">
        <!-- <div class="main-box" :style="{'width':this.$route.name == 'testTable'? '100%' : '90%'}"> -->
        <div
          class="main-box"
          :style="{
            width:
              this.$route.name == 'myProject' ||
              this.$route.name == 'myConsole' ||
              this.$route.name == 'overView' ||
              this.$route.name == 'newConsole'
                ? '100%'
                : '90%',
          }"
        >
          <transition name="fade-transform" mode="out-in">
            <!-- <keep-alive> -->
              <router-view :key="key" v-if="isRouterAlive"/>
            <!-- </keep-alive> -->
          </transition>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Storage from "../utils/localStorage"
import constants from "../constants/constants"
export default {
  name: "Dashboard",
  data() {
    return {
      vcorp_name: "",
      userName: "",
      userData: {},
      showHeader: true,
      dgKey: 1,
      currentCorpName: "",
      isRouterAlive: true
    };
  },
  created() {
    this.getUri();
    // this.getUserInfo()
  },
  mounted() {
    console.log(this.$store.state.corpList);
    this.getUserInfo();
  },
  computed: {
    key() {
      return this.$route.path;
    },
    corpList() {
      return this.$store.state.corpList;
    },
  },
  watch: {
    corpList(newVal, oldVal) {
      console.log(newVal);
    },
  },
  methods: {
    handleSelect(name) {
      this.$router.push({ name: name });
    },
    getUserInfo() {
      this.userData = this.common.getStorage(this.CONSTANTS.USER_INFO);
      if (this.userData) {
        if (this.userData.vname) {
          this.userName = this.userData.vname;
        } else {
          this.userName = this.userData.login_id;
        }
        this.currentCorpName = this.userData.vcorp_name;
        this.vcorp_name = this.userData.vcorp_name;
        this.dgKey = this.userData.dg_key;
      } else {
        // this.$Message.error("登录已过期,请重新登录");
        this.$router.push({ name: "login" });
      }
    },
    returnHome() {
      this.$router.push({ name: "home" });
    },
    logout() {
      this.common.removeAuth();
      this.$router.push({ name: "login" });
    },
    getUri() {
      let uri = this.$route.path;
      if (uri.indexOf("seller") != -1) {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
    },
    changeCorp(val){
      console.log(val);
      const storage = new Storage()
      const CONSTANTS = constants
      let token = storage.get(CONSTANTS.EOVA_AUTH);
      let that = this
      this.$Modal.confirm({
          title: "提示",
          content: `<p>是否切换企业</p>`,
          onOk: () => {
            that.currentCorpName = val.corpName
            // let data = {
            //   login_id: that.userData.login_id,
            //   icorpid: val.corpId,
            // }
            // that.$fetch("/sapi/eovaUser/getUserInfo", {
            //   method: "post",
            //   data,
            // }).then((res) => {
            //   if (res.code == "200") {
              let data = {
                corpName: val.corpName,
                corp_name: val.corpName,
                rid: val.maxRoleId,
                icorpid: val.corpId,
                login_id: token.phoneNumber,
                loginId: token.phoneNumber,
                vcorp_name: val.corpName,
                ssp_phone: token.phoneNumber,
                vname: token.vname,
              }
              if (val.maxRoleType === "CORP") {
                data.dg_key = 1
              }else if (val.maxRoleType === "SUB_CORP") {
                data.dg_key = 2
              }else if (val.maxRoleType === "PROJECT") {
                data.dg_key = 3
              }else if (val.maxRoleType === "TEAM") {
                data.dg_key = 4
              }
                that.common.setStorage(that.CONSTANTS.USER_INFO,data);
                that.reload()
            //   } else {
            //     that.$Message.warning(res.msg || "操作失败");
            //   }
            //   console.log(res)
            // });
          },
          onCancel: () => {

          },
        });
    },
    reload(){
      this.isRouterAlive = false
      this.$nextTick(()=>{
        this.isRouterAlive = true
      })
    }
  },
};
</script>

<style lang="stylus">
wrapper() {
  margin: 0 auto;
  padding: 0 30px;
}

body {
  background: #F2F2F2 !important;
}
.corp-box
  display flex
  align-items center
  cursor default
.header-nav-container {
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #dfdfdf;

  .header-nav-box {
    wrapper();
  }
}

.ds-main-container {
  width: 100%;
  background: #f2f2f2;

  .main-box {
    margin: 0 auto;
    width: 90%;
    min-width: 980px;
  }
}
</style>